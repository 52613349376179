import React, { Component } from "react"

import Autocomplete from "components/Autocomplete/Autocomplete"
import Modal from "components/Modal/Modal"
import ResourceContent from "components/PunchClockResourceContent/PunchClockResourceContent"
import MessageBox from "components/MessageBox/MessageBox"
// HELPERS
import moment from "moment"
import * as utils from "helpers/utils"
import i18n from "helpers/i18n"
import { getAssetsURIForPath } from "helpers/api"
import Settings from "classes/Settings"
import styled, { css } from "styled-components"
import PunchClockLogin from "routes/PunchClockLogin/PunchClockLogin"

export default class PunchClockResources extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "",
            currentTime: moment().format("HH:mm:ss"),
            currentDate: moment().format("YYYY-MM-DD"),
            userID: "",
            animate: true
        }
    }

    componentDidMount() {
        var searchLabel = document.getElementById("search").querySelectorAll("label")
        searchLabel[0].style.color = Settings.getGlobalColor("label")

        this.setState({ componentDidMount: true })
    }

    componentDidUpdate() {
        const { props, state } = this

        if (!!state.resourceContent) {
            let currentContent = state.resourceContent
            let resourceIndex = props.resources.findIndex(resource => resource.ID === currentContent.props.resource.ID)
            if (currentContent.props.resource.ID === props.resources[resourceIndex].ID) {
                if (currentContent.props.resource.activeProjects.length !== props.resources[resourceIndex].activeProjects.length) {
                    this.setState({
                        resourceContent: {
                            ...currentContent,
                            props: {
                                ...currentContent.props,
                                resource: props.resources[resourceIndex]
                            }
                        }
                        
                    })
                }

            }
        }
    }

    async updateSearch(e, value) {
        const time = 400
        if (e.label) {
            this.setState({ search: e.label })
        } else {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.setState({ search: value })
            }, time)
        }
    }

    handleResourceClick(resource, e) {
        this.setState({ animate: true })
        this.setResourceContent(resource, e.currentTarget)
    }

    setResourceContent(resource, resourceTarget) {
        if (!resource.startArray) resource.startArray = []
        if (!resource.stopArray) resource.stopArray = []
        if (!resource.activeProjects) resource.activeProjects = [] 
        document.getElementsByClassName("PunchClockBoard")[0].style.overflowY = "hidden"

        if (resource.projects.length > 0) {
            this.setState({
                resourceContent: (
                    <ResourceContent
                        name={resource.FirstName + " " + resource.LastName}
                        resource={resource}
                        projects={resource.projects}
                        onClose={this._onCloseResourceContent.bind(this)}
                        resourceTarget={resourceTarget}
                        reportTime={this.props.reportTime}
                        timeElapsed={this.props.timeElapsed}
                        onSingleReset={this.props.onSingleReset}
                        onStartHandler={this.props.onStartHandler}
                        onStopHandler={this.props.onStopHandler}
                    />
                ),
            })
        } else {
            //show error if project doesn't have any projects assigned to them
            let fullName = resource.FirstName + " " + resource.LastName
            MessageBox(this, {
                message: utils.format(i18n("errors", "no_project"), [fullName]),
                buttons: [{
                    label: "OK",
                    appearance: "primary",
                    onClick: () => {
                        this._onCloseResourceContent()
                    }
                }]
            })
        }
    } 

    _onCloseResourceContent() {
        document.getElementsByClassName("PunchClockBoard")[0].style.overflowY = "auto"
        if (this.state.search) {
            this.textInputRef.getInfo("")
        }
        this.setState({
            resourceContent: null,
            search: "",
        })
    }

    timeElapsed = (startArray, stopArray) => {
        let HSM = this.props.timeElapsed(startArray, stopArray)
        return HSM
    }

    onStartHandler(resource, project) {
        this.setState({ animate: false })
        this.props.onStartHandler(resource, project)
    }

    onStopHandler(resource, project) {
        this.setState({animate: false})
        this.props.onStopHandler(resource, project)
    }

    render() {
        const { state, props } = this
        const { resources } = props

        const users = resources.map((resource) => {
            const name = resource.FirstName + " " + resource.LastName
            let search = state.search ? state.search : ""
            let filtered = name.toLowerCase().includes(search.toLowerCase())
            const active = !!resource.activeProjects.length > 0
            return (
                <Div
                    key={resource.ID}
                    style={{
                        visibility: !filtered ? "hidden" : "visible",
                        position: !filtered ? "absolute" : "relative",
                        background: active
                            ? Settings.getGlobalColor("success")
                            : Settings.getGlobalColor("overlay"),
                    }}
                    className="items"
                    onClick={this.handleResourceClick.bind(this, resource)}
                >
                    <img
                        className="img"
                        src={getAssetsURIForPath(resource.Photo)}
                        alt=""
                        style={{ background: Settings.getGlobalColor("label") }}
                    />
                    <div className="name">
                        <H2 $active={active}> {name} </H2>
                    </div>
                </Div>
            )
        })  
    
        return (
            <React.Fragment>
                <Modal
                    key={ this.state.animate }
                    animate={ this.state.animate }
                    visible={ this.state.resourceContent !== null }
                    onClosed={ this._onCloseResourceContent.bind(this) }
                >
                    {this.state.resourceContent}
                </Modal>
                {this.state.MessageBox}

                <Div className="TimeReportOverview-top-bar">
                    <div className="start">
                        <div className="search" id="search">
                            <I className={"icon material-icons"}>search</I>
                            <Autocomplete
                                label={i18n("general", "search")}
                                value={this.state.search}
                                onChange={this.updateSearch.bind(this)}
                                onSelect={this.updateSearch.bind(this)}
                                searchUrl={props.searchUrl}
                                ref={(ref) => {
                                    this.textInputRef = ref
                                }}
                            />
                        </div>
                    </div>

                    <div className="end">
                        <StyledButton className="button" onClick={() => this.setState({ showPunchClockLogin: true })}>
                            <div className="icon material-icons">account_circle</div>
                        </StyledButton>
                    </div>
                </Div>

                <Modal visible={this.state.showPunchClockLogin} onClosed={() => this.setState({ showPunchClockLogin: false })}>
                    <PunchClockLogin/>
                </Modal>

                <div id="punchClockResources" className="itemsContainer">
                    {users}
                </div>

            </React.Fragment>
        )}
    }

const I = styled.i`
    color: ${props => props.theme.label}
`
const Div = styled.div`
    background: ${props => props.theme.overlay}
`
const H2 = styled.h2`
    color: ${props => props.theme[props.$active ? "overlay" : "primary"]}
`
const StyledButton = styled.div`
    color: ${props => props.theme.subtitle};
    &:hover {
        background: ${props => props.theme.background};
    }

    ${props => props.className.split(' ').includes('opened') && css`
        background: ${props.theme.overlay} !important;
    `}
`