import React, {Component} from 'react'
import { connect } from 'react-redux'

import Statistics from "components/Statistics/Statistics";
import styled, {css} from 'styled-components';

import { getAssetsURIForPath } from "helpers/api";
import * as utils from "helpers/utils";

class ShadowCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalHours: 0,
            totalCost: 0,
            totalFee: 0,
            totalSalary: 0,
            extraFee: 0,
            extraCost: 0,
            pension: 0
        }
    }

    componentDidMount() {
        this._refresh()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.data !== this.props.data) this._refresh()
    }

    componentWillUnmount() {
        this._refresh()
    }

    _onContextReviewClick = () => {
        const { data, onContextReviewClick } = this.props;
        if(onContextReviewClick) onContextReviewClick(data.id, data.context, 'project');
    }

    _refresh () {
        const { data, period } = this.props, { context, rows } = data

        let periodStartDate = utils.moment(period.startDate)
        let periodEndDate = utils.moment(period.endDate)
    
        const total = utils.getRowStatistics(rows)
        const extra = utils.getExtraStatistics(context.extras, periodStartDate, periodEndDate)
        
        this.setState({
            totalHours: (Math.round(total.hours * 2) / 2),
            totalFee: (Math.round(total.fee * 2) / 2),
            totalCost: (Math.round(total.cost * 2) / 2),
            totalSalary: (Math.round(total.salary * 2) / 2),
            extraCost: (Math.round(extra.cost * 2) / 2),
            extraFee: (Math.round(extra.fee * 2) / 2),
        })
    }

    render() {
        const { data, mode } = this.props
        const { totalHours, totalCost, totalFee, totalSalary, extraFee, extraCost } = this.state
        const { avatarPath, name, assignings } = data.context
        const filteredAssignings = utils.filterDuplicatesByKey(assignings, "UserID_FK")

        return (
            <Div className="Timeline-toolbar shadow-card" onClick={this._onContextReviewClick}>
                <div className="context-info">
                    <Div className="avatar" style={{ backgroundImage: `url(${getAssetsURIForPath(avatarPath)})` }} />
                    <Div className="name">{ name }</Div>

                    <div className='assigned'>
                        {filteredAssignings.map((e,i) => {
                            if (i < 3) return <p key={e.UserID_FK +'r'+ i}>{e.UserName}</p>
                            return ''
                        })}
                        {filteredAssignings.length > 3 && (<i className="fas fa-ellipsis-v"></i>)}
                    </div>

                    <Statistics 
                        type="shadows"
                        mode={mode}
                        data={{
                            totalHours,
                            totalFee,
                            totalCost,
                            totalSalary,
                            extraFee,
                            extraCost
                        }}
                    />
                </div>
            </Div>
        )
    }
}

export default connect()(ShadowCard)


const Div = styled.div`
    ${props => props.className.split(' ').includes('Timeline-toolbar') && css`
        border-bottom: 1px solid ${props.theme.background};
    `}
    ${props => props.className.split(' ').includes('avatar') && css`
        
    `}
    ${props => props.className.split(' ').includes('name') && css`
        color: ${props.theme.primary};
    `}
    ${props => props.className.split(' ').includes('shadow-card') && css`
        background: ${props.theme.overlay};
    `}
`;
