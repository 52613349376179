import React from 'react'
import Settings from "classes/Settings";
import FilterView from "../../routes/TimeReport/components/FilterView/FilterView";
import i18n from "helpers/i18n";
import * as utils from "helpers/utils";
import styled, {css} from 'styled-components';

export default function Statistics(props) {
    const { isManager, mode, filterModeVisible, onFilterChange, onModeToggle, data, type, modeType } = props
    const { overheadFee, overheadCost, totalHours, totalFee, totalCost, totalSalary, extraFee, extraCost } = data

    const styleOptions = {
        textColor: ["cost", "salary"].includes(mode) ? "danger" : "subtitle",
        hasBorder: type === "timeline"
    }

    const statistics = (
        <div className="values">
            {{
                "hours":
                    <Sum $options={styleOptions} className="value total-hours" title={ totalHours.toLocaleString(Settings.get("REGION_FORMAT")) + " " + i18n("timereport", "hours_reported") }>
                        <Div className="icon material-icons">access_time</Div>
                        <span id="value">
                            {totalHours % 1 === 0 
                                ? totalHours.toLocaleString(Settings.get("REGION_FORMAT")) + ' h'
                                : <span> {(totalHours-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span>{' h'}</span>
                            }
                        </span>
                    </Sum>,
                "fee":
                    <Sum $options={styleOptions} className="value total-fee" title={ totalHours.toLocaleString(Settings.get("REGION_FORMAT")) + " " + i18n("timereport", "hours_reported") }>
                        {/* <div className="icon material-icons">attach_money</div> */}
                        <span id="value">
                            {totalFee % 1 === 0 ? totalFee.toLocaleString(Settings.get("REGION_FORMAT")) :<span> {(totalFee-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span></span> }
                            {!!extraFee && ( extraFee % 1 === 0 ? " + " + extraFee.toLocaleString(Settings.get("REGION_FORMAT")):<span> {" + " + (extraFee-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span></span>)}
                            {!!overheadFee && ( overheadFee % 1 === 0 ? " + " + overheadFee.toLocaleString(Settings.get("REGION_FORMAT")):<span> {" + " + (overheadFee-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span></span> )}
                            {!!(extraFee+overheadFee) && ((totalFee+overheadFee+extraFee) % 1 === 0 ? " = " + (totalFee+overheadFee+extraFee).toLocaleString(Settings.get("REGION_FORMAT")) :<span> {" = " + (totalFee+overheadFee+extraFee-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span></span> )}
                            {` ${Settings.get("CURRENCY")}`}
                        </span>
                    </Sum>,
                "cost":
                    <Sum $options={styleOptions} className="value total-cost" title={ totalHours.toLocaleString(Settings.get("REGION_FORMAT")) + " " + i18n("timereport", "hours_reported") }>
                        {/* <div className="icon material-icons">attach_money</div> */}
                        <span id="value">
                            {totalCost % 1 === 0 ? totalCost.toLocaleString(Settings.get("REGION_FORMAT")) :<span> {(totalCost-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span></span> }
                            {!!extraCost && ( extraCost % 1 === 0 ? " + " + extraCost.toLocaleString(Settings.get("REGION_FORMAT")):<span> {" + " + (extraCost-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span></span>)}
                            {!!overheadCost && ( overheadCost % 1 === 0 ? " + " + overheadCost.toLocaleString(Settings.get("REGION_FORMAT")):<span> {" + " + (overheadCost-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span></span> )}
                            {!!(extraCost+overheadCost) && ((totalCost+overheadCost+extraCost) % 1 === 0 ? " = " + (totalCost+overheadCost+extraCost).toLocaleString(Settings.get("REGION_FORMAT")) :<span> {" = " + (totalCost+overheadCost+extraCost-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span></span> )}
                            {` ${Settings.get("CURRENCY")}`}
                        </span>
                    </Sum>,
                "salary":
                    <Sum $options={styleOptions} className="value total-salary" title={ totalHours.toLocaleString(Settings.get("REGION_FORMAT")) + " " + i18n("timereport", "hours_reported") }>
                        {/* <div className="icon material-icons">attach_money</div> */}
                        {totalSalary % 1 === 0 ? <span>{totalSalary.toLocaleString(Settings.get("REGION_FORMAT")) + ` ${Settings.get("CURRENCY")}`}</span>
                        : <span> {(totalSalary-0.5).toLocaleString(Settings.get("REGION_FORMAT"))+","}<span className="decimal">{'5'}</span>{` ${Settings.get("CURRENCY")}`}</span> }
                    </Sum>
            }[mode]}
        </div>
    )

    if (type === "total") {
        return (
            <Div style={{ borderLeft: 0 }} className={ utils.createClassName("statistics", { "opened": filterModeVisible }) } title={ i18n("general", "mode") } onClick={ isManager && onModeToggle }>
                <Div className="label">
                    {
                        mode === "hours"  ? i18n("general", "Hours").toUpperCase() :
                        mode === "fee"    ? i18n("general", "fee").toUpperCase() + (!!extraFee ? " + " + i18n("general", "extraFee").toUpperCase() : '') + (!!overheadFee ? " + " + i18n("general", "overheadFee").toUpperCase() : '') :
                        mode === "cost"   ? i18n("general", "cost").toUpperCase() + (!!extraCost ? " + " + i18n("general", "extraCost").toUpperCase() : '') + (!!overheadCost ? " + " + i18n("general", "overheadCost").toUpperCase() : '') :
                        mode === "salary" ? i18n("general", "salary").toUpperCase() :
                        i18n("general", "unknown").toUpperCase()
                    }
                </Div>

                {statistics}

                <FilterView isManager={isManager} type={modeType} visible={ filterModeVisible } onClose={ onModeToggle } onFilterChange={ onFilterChange } />
            </Div>
        )
    } else {
        return statistics
    }
}

const Div = styled.div`
    ${props => props.className === 'TimeReportOverview-top-bar' && css`
        background: ${props.theme.overlay};
    `}
    ${props => props.className === 'statistics' && css`
        border-left: 1px solid ${props.theme.background};
        @media (min-width: 640px) {
            &:hover {
              background: ${props.theme.background};
            }
        }
        // @media (max-width: 640px) {
        //     border-top: 1px solid ${props.theme.background} !important
        // }
    `}
    ${props => props.className === 'label' && css`
        color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('icon') && css`
        color: ${props.theme.label};
    `}
`;

const Sum = styled.div`
    color: ${props => Settings.getGlobalColor(props.$options.textColor)};

    ${props => props.$options.hasBorder && css`
        border-left: 1px solid ${props.theme.label};
    `};
`;