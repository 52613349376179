import React from "react"
import PropTypes from "prop-types"
import styled, {css} from 'styled-components'

// COMPONENTS
import Button from "components/Button/Button"
import Select from "components/Select/Select"
import EditableField from "components/EditableField/EditableField"
import Table from "components/Table/Table"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import Autocomplete from "components/Autocomplete/Autocomplete"
import DatePicker from "components/DatePicker/DatePicker"
import Switch from "components/Switch/Switch"

// HELPERS
import * as utils from "helpers/utils"
import i18n from "helpers/i18n"
import moment from "moment"


export default class EditableTable extends React.PureComponent {
    static propTypes = {
        context: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        columns: PropTypes.array.isRequired,
        rows: PropTypes.array.isRequired,
        onRowChecked: PropTypes.func,
        onRemoveSelected: PropTypes.func,
        onAddRow: PropTypes.func,
        onSuggestionSelected: PropTypes.func,
        onPayRollChanged: PropTypes.func,
        onColumnChange: PropTypes.func,
    }

    render() {
        const { context, type, columns, rows, disableHourlyFee, onAssignAll, _onShowTagWindow, markedPeriods, footerTemplate } = this.props
        let nTextboxes = 0

        return <Table columns={columns} type={type}
            rows={type === "wages" ? rows.map((row, rowIndex) => {
                if (row.delete) return null
                nTextboxes++

                return <React.Fragment>
                    <input type="checkbox" checked={row.selected} onChange={_onRowChecked.bind(this, context, type, 'selected', rowIndex)} />
                    <DatePicker date={row.StartDate ? utils.moment(row.StartDate) : null}
                        onDateChange={_onColumnChangeAsync.bind(context, type, 'StartDate', rowIndex)} />
                    <EditableField key={rowIndex + 'h'} type="text" disabled={!!row.MonthlySalary} value={!row.MonthlySalary ? row.HourlySalary : ''} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'HourlySalary', rowIndex)} split2 table />
                    <EditableField key={rowIndex + 'm'} type="text" disabled={!!row.HourlySalary} value={!row.HourlySalary ? row.MonthlySalary : ''} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'MonthlySalary', rowIndex)} split2 table />
                    <EditableField key={rowIndex + 'w'} type="text" value={row.WorkPercent} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'WorkPercent', rowIndex)} split2 table />
                    <Switch key="payroll" xsmall name="Payroll" animate={!rows.stopAnimation} index={rowIndex} checked={!!row.Payroll}
                        onChange={_onPayrollSwitchChanged.bind(this)} Fixed /> 
                    <EditableField key={rowIndex + 'p'}  type="text" value={row.Pension} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'Pension', rowIndex)} split2 table />
                </React.Fragment>
            }) : type === 'overhead' ? rows.map((row, rowIndex) => {
                if (row.delete) return null

                let yearOptions = []
                for (let index = -10; index <= 10; index++) {
                    yearOptions.push({ value: parseInt(utils.moment().format("YYYY"), 10) + index, label: parseInt(utils.moment().format("YYYY"), 10) + index })
                }

                let monthOptions = []
                for (let index = 1; index <= 12; index++) {
                    monthOptions.push({ value: index > 9 ? index : "0" + index, label: utils.moment(index, 'MM').format("MMM") })
                }
              
                let startYear = utils.moment(row.StartDate).format("YYYY")
                let startYearIndex = yearOptions.findIndex(item => +item.value === +startYear)

                let startMonth = utils.moment(row.StartDate).format("MM")
                let startMonthIndex = monthOptions.findIndex(item => +item.value === +startMonth)

                let endYear = utils.moment(row.EndDate).format("YYYY")
                let endYearIndex = yearOptions.findIndex(item => +item.value === +endYear) + 1

                let endMonth = utils.moment(row.EndDate).format("MM")
                let endMonthIndex = monthOptions.findIndex(item => +item.value === +endMonth) + 1

                nTextboxes++

                return <React.Fragment>
                    <input type="checkbox" checked={row.selected === true}
                        onChange={_onRowChecked.bind(this, context, type, 'selected', rowIndex)} />
                    <div className="overheadDates">
                        <div className="year">
                            <Select name="startYear"
                                selectedIndex={ startYearIndex === -1 ? 10 : startYearIndex }
                                onChange={_onColumnSelectChange.bind(context, type, 'startYear', rowIndex)}
                                options={yearOptions} />
                        </div>
                        <div className="month">
                            <Select name="startMonth"
                                selectedIndex={ startMonthIndex === -1 ? moment().format("M") - 1 : startMonthIndex }
                                onChange={_onColumnSelectChange.bind(context, type, 'startMonth', rowIndex)}
                                options={monthOptions} />
                        </div>
                    </div>
                    <div className="overheadDates">
                        <div className="year">
                            <Select name="endYear"
                                selectedIndex={ endYearIndex }
                                onChange={_onColumnSelectChange.bind(context, type, 'endYear', rowIndex)}
                                options={[{value: "none", label: "None"}].concat(yearOptions)} />
                        </div>
                        <div className="month">
                            <Select name="endMonth"
                                selectedIndex={ endMonthIndex }
                                onChange={_onColumnSelectChange.bind(context, type, 'endMonth', rowIndex)}
                                options={[{value: "none", label: "None"}].concat(monthOptions)} />
                        </div>
                    </div>
                    <EditableField key={'Fee'}  type="text" value={row.Fee ? row.Fee : ''} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'Fee', rowIndex)} split2 table />
                    <EditableField key={'Cost'}  type="text" value={row.Cost ? row.Cost : ''} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'Cost', rowIndex)} split2 table />
                    <EditableField key={'Label'}  type="text" value={row.Label ? row.Label : ''} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'Label', rowIndex)} split2 table />
                </React.Fragment>
            }) : type === 'assignings' ? rows.map((row, rowIndex) => {
                if (row.delete) return null

                nTextboxes++

                return <React.Fragment>
                    <input type="checkbox" checked={row.selected === true} onChange={_onRowChecked.bind(this, context, type, 'selected', rowIndex)} />
                    <Autocomplete
                        value={row.UserName}
                        item={row.UserID_FK}
                        onSelect={_onSuggestionSelected.bind(context, rowIndex)}
                        rowIndex={rowIndex}
                        onChange={this._onAutocompleteFieldChange}
                        searchUrl="/users/general/search/"
                        key={ row.UserID_FK }
                        small />
                    <div className={disableHourlyFee ? 'disabled' : ''}>
                        <EditableField key='visibleInput-Fee' type="text"value={row.Fee} id={nTextboxes} disabled={disableHourlyFee} 
                            onChange={_onColumnChangeAsync.bind(context, type, 'Fee', rowIndex)} split2 table />
                    </div>
                    <div>
                        <EditableField key='visibleInput-SM' type="text" value={row.SM} id={nTextboxes}
                            onChange={_onColumnChangeAsync.bind(context, type, 'SM', rowIndex)} split2 table />
                    </div>
                    
                    <DatePicker date={row.StartDate ? utils.moment(row.StartDate) : null} markedPeriods={markedPeriods} rowID={row.ID}
                        onDateChange={_onColumnChangeAsync.bind(context, type, 'StartDate', rowIndex)} />
                    <DatePicker date={row.EndDate ? utils.moment(row.EndDate) : null} markedPeriods={markedPeriods} rowID={row.ID}
                        onDateChange={_onColumnChangeAsync.bind(context, type, 'EndDate', rowIndex)} />
                </React.Fragment>
            }) : type === 'affectedAssignings' ? rows.map((row, rowIndex) => {
                if (row.delete) return null
                nTextboxes++
                
                return <React.Fragment>
                    <i className='fas fa-trash-alt' onClick={() => this.props.onTrashClick(rowIndex)} />
                    <p>{row.ProjectName}</p>
                    <DatePicker date={row.StartDate ? utils.moment(row.StartDate) : null} markedPeriods={markedPeriods} rowID={row.ID}
                        onDateChange={_onColumnChangeAsync.bind(context, type, 'StartDate', rowIndex)} />
                    <DatePicker date={row.EndDate ? utils.moment(row.EndDate) : null} markedPeriods={markedPeriods} rowID={row.ID}
                        onDateChange={_onColumnChangeAsync.bind(context, type, 'EndDate', rowIndex)} />
                </React.Fragment>
                
                
            }) : type === 'extras' ? rows.map((row, rowIndex) => {
                if (row.delete) return null
                nTextboxes++
                return <React.Fragment>
                    <input type="checkbox" checked={row.selected} onChange={_onRowChecked.bind(this, context, type, 'selected', rowIndex)} />
                    <DatePicker date={row.StartDate ? utils.moment(row.StartDate) : null}
                        onDateChange={_onColumnChangeAsync.bind(context, type, 'StartDate', rowIndex)} />
                    <DatePicker date={row.EndDate ? utils.moment(row.EndDate) : null}
                        onDateChange={_onColumnChangeAsync.bind(context, type, 'EndDate', rowIndex)} />
                    <EditableField key={'Label'}  type="text" value={row.Label ? row.Label : ''} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'Label', rowIndex)} split2 table />
                    <EditableField key={'Fee'}  type="text" value={row.Fee ? row.Fee : ''} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'Fee', rowIndex)} split2 table />
                    <EditableField key={'Cost'}  type="text" value={row.Cost ? row.Cost : ''} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'Cost', rowIndex)} split2 table />
                    <div style={{textAlign:"left", marginTop:"8px", overflow:"hidden"}}>{`${(row.Fee ? row.Fee : 0) - (row.Cost ? row.Cost : 0)}`}</div>
                    {/* <EditableField key={'Profit'}  type="text" value={(row.Fee ? row.Fee : 0) - (row.Cost ? row.Cost : 0)} id={nTextboxes}
                        onChange={_onColumnChangeAsync.bind(context, type, 'Profit', rowIndex)} split2 table/> */}
                </React.Fragment>
            }) : type === 'activities' ? rows.map((row, rowIndex) => {
                if (row.delete) return null
                
                nTextboxes++

                return <React.Fragment>
                    <input type="checkbox" checked={row.selected === true}
                        onChange={_onRowChecked.bind(this, context, type, 'selected', rowIndex)} />
                    <Status active={row.Active}>{row.Active ? i18n('globals', 'active') : i18n('globals', 'inactive')}</Status>
                    <DatePicker date={row.StartDate ? utils.moment(row.StartDate) : null} markedPeriods={markedPeriods} rowID={row.ID}
                        onDateChange={_onColumnChangeAsync.bind(context, type, 'StartDate', rowIndex)} />
                    <DatePicker date={row.EndDate ? utils.moment(row.EndDate) : null} markedPeriods={markedPeriods} rowID={row.ID}
                        onDateChange={_onColumnChangeAsync.bind(context, type, 'EndDate', rowIndex)} />
                    {/* <Switch key="active" xsmall animate={!rows.stopAnimation} name="Active" index={rowIndex} checked={!!row.Active}
                        onChange={_onStatusSwitchChanged.bind(this)} /> */}
                </React.Fragment>
            }) : type === 'reminders' ? rows.map((row, rowIndex) => {
                return <React.Fragment>
                    <input key={utils.createUUID() } type="checkbox" checked={ row.selected === true}
                        onChange={_onRowChecked.bind(this, context, type, 'selected', rowIndex)} />
                    <Autocomplete
                        value={row.name}
                        item={row.id}
                        label={''}
                        onSelect={_onReminderSuggestionSelected.bind(context, rowIndex)}
                        onChange={this._onAutocompleteFieldChange}
                        searchUrl="/punchClock/users/search/"
                        key={`visibleInput-${ utils.createUUID()}`}
                        style={{ width:'100%' }}
                        chosenAssets={rows}
                        small />
                </React.Fragment>        
            }) : type === 'punchclock' ? rows.map((row, rowIndex) => {
                if (row.delete) return null

                return <React.Fragment>
                    <input key={utils.createUUID() } type="checkbox" checked={ row.selected === true}
                        onChange={_onRowChecked.bind(this, context, type, 'selected', rowIndex)} />
                    <Autocomplete
                        value={row.name}
                        item={row.id}
                        label={''}
                        onSelect={_onTagSuggestionSelected.bind(context, rowIndex)}
                        onChange={this._onAutocompleteFieldChange}
                        searchUrl="/punchClock/users/search/"
                        key={`visibleInput-${ utils.createUUID()}`}
                        style={{ width:'100%' }}
                        chosenAssets={rows}
                        small />
                </React.Fragment>
            }) : type === 'tags' ? rows.map((row, rowIndex) => {
                if (row.delete) return null

                return <React.Fragment>
                    <input key={utils.createUUID()} type="checkbox" style={{ float:'right' }} checked={row.selected === true}
                        onChange={_onRowChecked.bind(this, context, type, 'selected', rowIndex)} />
                    <Autocomplete
                        value={row.name}
                        item={row.id}
                        label={''}
                        onSelect={_onTagSuggestionSelected.bind(context, rowIndex)}
                        onChange={this._onAutocompleteFieldChange}
                        searchUrl="/tags/search/"
                        key={`visibleInput-${ utils.createUUID() }`}
                        style={{ width:'100%' }}
                        chosenAssets={rows}
                        small />
                    <ShadowSign className ="shadow-sign" style={{
                            width: '44px',
                            fontSize: '15px',
                            borderRadius: '6px',
                            marginTop: '5px',
                            display: row.shadow ? 'block' : 'none' }}>
                        <i className="far fa-eye-slash"></i>
                    </ShadowSign>
                </React.Fragment>
            }) : ''}
            renderFooterTemplate={footerTemplate ? footerTemplate :
                <div style={{ width: "100%" }}>
                    <StackLayout justifyContent="space-between" rowWrap fullWidth>
                        <Button size="small" label={i18n("general", "remove_selected")} appearance="danger" onClick={_onRemoveSelected.bind(this, type)} />
                        {type === 'assignings' && (<Button size="small" label={i18n("general", "add_all")} appearance="secondary" filled onClick={onAssignAll } />)}
                        {type === 'assignings' ? <Button size="small" label={i18n("general", "add_by_tags")} appearance="secondary" filled onClick={_onShowTagWindow }/> : ''}
                        <Button size="small" label={i18n("general", type === "wages" ? "add_wage_growth" : (type === 'overhead' ? 'add_overhead' :
                            (type === "assignings" ? "add_resource" : type === "activities" ? "add_activity_period" : "add")))}
                                appearance="secondary" filled onClick={_onAddRow.bind(this, type, (type === 'activities' && rows.filter(e => !e.delete).length > 0 && rows.filter(e => !e.delete)[0]) || null)} />
                    </StackLayout>
                    <input type="hidden" name={type} value={JSON.stringify(rows.map((row, rowIndex) => {
                        const result = {}

                        for (let i = 0; i < columns.length; i++) {
                            const key = columns[i].name
                            if (typeof key !== "undefined") {
                                const value = typeof row[key] === "object" && row[key] !== null ? row[key].value : row[key]
                                result[key] = value === "" ? "" : value
                            }
                        }

                        return result
                    }))} />
                </div>
            }
        />
    }

    _onAutocompleteFieldChange = (name, value, rowIndex) => {
        const { onChange } = this.props
        if (onChange) onChange(name,value, rowIndex)
    }
}


// PRIVATE FUNCTIONS
function _onRowChecked(context, type, colKey, rowIndex, { target }) {
    const { onRowChecked } = this.props
    if (onRowChecked) onRowChecked(type, colKey, rowIndex, target.checked)
}

function _onPayrollSwitchChanged(checked, index) {
    const { onPayRollChanged } = this.props
    if (onPayRollChanged) onPayRollChanged(checked, index)
}

function _onSuggestionSelected(rowIndex, item, allowDuplicate = true) {
    const row = this.state.data['assignings'][rowIndex]
    row['UserID_FK'] = item.id
    row['UserName'] = item.label
    this.setState(this.state['assignings'][rowIndex] = row)
}


function _onTagSuggestionSelected(rowIndex, item) {
    const editedItem = {
        isNew: true,
        selected: this.state.data[rowIndex].selected,
        ID: item.data.ID,
        name: item.label,
        type: item.type
    }

    if (item.type === 'project') editedItem.shadow = item.shadow

    this.state.data[rowIndex] = editedItem
    if (this._onAutoCompleteSelected) this._onAutoCompleteSelected(editedItem)

}

function _onReminderSuggestionSelected(rowIndex, item) {

    const editedItem = {
        isNew: true,
        selected: this.state.recipients[rowIndex].selected,
        ID: item.data.ID,
        name: item.label,
    }

    this.state.recipients[rowIndex] = editedItem
    if (this._onAutoCompleteSelected) this._onAutoCompleteSelected(editedItem)

}

function _onColumnChangeAsync(type, colKey, rowIndex, { target }) {
    if (!target) return

    var value = target.value
    
    const rows = [...this.state.data[type]]

    if (colKey === 'Fee') {
        if (this._onFeeFieldChange) this._onFeeFieldChange()
    }

    if (["Fee", "Cost", "HourlySalary", "MonthlySalary", "Pension", "SM", "WorkPercent"].includes(colKey)) {
        if (isNaN(value) || value[0] === "-" || (colKey === "WorkPercent" && value.includes("."))) {
            return
        } else {
            value = parseInt(value, 10) === 0 ? value : value.replace(/^0+/, '') //unless value is zero, remove all leading zeros
        }
    } 
    rows[rowIndex][colKey] = value

    this.setState({
        data: {
          ...this.state.data,
          [type]: rows
        }
    }, () => {
        if (type === "activities") {
            switch(colKey) {
                case "EndDate":
                    if (!+rowIndex) { this._onAddRow(type, (rows.filter(e => !e.delete).length > 0 && rows.filter(e => !e.delete)[0]) || null) }
                    else if (!!rows[rowIndex - 1]) {
                        rows[rowIndex-1].StartDate = utils.moment(value).add(1, 'days').format('YYYY-MM-DD')
                        this.setState({data: {...this.state.data, [type]: rows}})
                    }
                    break;
                case "StartDate":
                    if (!!rows[rowIndex + 1]) {
                        rows[rowIndex+1].EndDate = utils.moment(value).subtract(1, 'days').format('YYYY-MM-DD')
                        this.setState({data: {...this.state.data, [type]: rows}})
                    }
                    break;
                default: break;
            }
        }
    })
}

function _onColumnSelectChange(type, colKey, rowIndex, result) {
    var value = result.value
    const rows = [...this.state.data[type]]
    var newValue;
    var date;

    switch(colKey) {
        case "startYear":
            if (value === "none") {
                newValue = null
            } else {
                date = !rows[rowIndex].StartDate || rows[rowIndex].StartDate === "none" ? utils.moment() : utils.moment(rows[rowIndex].StartDate)
                newValue = date.format(`${value}-MM-01`)
            }
            colKey = "StartDate"
            break;
        case "startMonth":
            if (value === "none") {
                newValue = null
            } else {
                date = !rows[rowIndex].StartDate || rows[rowIndex].StartDate === "none" ? utils.moment() : utils.moment(rows[rowIndex].StartDate)
                newValue = date.format(`YYYY-${value}-01`)
            }
            colKey = "StartDate"
            break;
        case "endYear":
            if (value === "none") {
                newValue = null
            } else {
                date = !rows[rowIndex].EndDate || rows[rowIndex].EndDate === "none" ? utils.moment() : utils.moment(rows[rowIndex].EndDate)
                newValue = date.endOf('month').format(`${value}-MM-DD`)
            }
            colKey = "EndDate"
            break;
        case "endMonth":
            if (value === "none") {
                newValue = null
            } else {
                date = !rows[rowIndex].EndDate || rows[rowIndex].EndDate === "none" ? utils.moment() : utils.moment(rows[rowIndex].EndDate)
                let lastDay = utils.moment(value, "MM").endOf("month").format("DD")
                newValue = date.format(`YYYY-${value}-${lastDay}`)
            }
            colKey = "EndDate"
            break;
        default: break
    }
    rows[rowIndex][colKey] = newValue

    this.setState({
        data: {
          ...this.state.data,
          [type]: rows
        }
    })
}

function _onRemoveSelected(type) {
    const { onRemoveSelected } = this.props
    if (onRemoveSelected) onRemoveSelected(type)
}

async function _onAddRow(type, firstEl) {
    const { onAddRow } = this.props
    if (onAddRow) await onAddRow(type, firstEl)
}

const Status = styled.div`
    font-size: 16px;
    margin-top: 5px;
    ${props => props.active ? css`
        color: ${props.theme.success}`
    : css`
        color: ${props.theme.label}`
    }
`

const ShadowSign = styled.div`
    color: ${props => props.theme.label};
`;