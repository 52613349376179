import React from "react";

// CLASSES
import Settings from "classes/Settings";
import styled, {css} from 'styled-components';

// COMPONENTS
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import DatePicker from "components/DatePicker/DatePicker";
import Modal from "components/Modal/Modal";
import Timeline from "components/Timeline/Timeline";
import Statistics from "components/Statistics/Statistics";
import ContextReviewContent from "../../../components/ContextReviewContent/ContextReviewContent";
import FilterView from "../../../components/FilterView/FilterView";

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// HELPERS
import * as utils from "helpers/utils";
import api from "helpers/api";
import i18n from "helpers/i18n";

export default class ProjectsView extends React.PureComponent {
    state = {
        period: null,
        timelines: null,
        unreportedDates: null,
        totalHours: 0,
        totalFee: 0,
        totalCost: 0,
        extraCost: 0,
        extraFee: 0,
        hasOpenedReportTimeWindowOnce: true,
        filterViewVisible: false,
        filterModeVisible: false,
        contextReviewContent: null,
        newProjectContent: null,
    }

    async componentDidMount() {
        this._isMounted = true
        const { timeReporter } = this.props

        timeReporter.addListener("change", this._onTimeReporterChange)
        
        if (!timeReporter.getTimelines("projects")) await this.props.fetchDataAsync()
        // else _refreshTimeReportData.call(this)
    }

    componentWillUnmount() {
        this._isMounted = false
        const { timeReporter } = this.props
        timeReporter.removeListener("change", this._onTimeReporterChange)
        timeReporter.clearTimelines("projects")
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) return

        const orderedTimelines = reorder(
            this.state.timelines,
            result.source.index,
            result.destination.index,
        )

        const sourceProjectId = result.draggableId
        const destinationProjectId = this.state.timelines.filter((timeline, i) => i === result.destination.index)[0].id

        const data = {
            id: this.props.user.id,
            sourceProjectId: sourceProjectId,
            destinationProjectId: destinationProjectId
        }

        const url = '/project/saveProjectOrder'
        api(url, data)

        this.setState({ timelines: orderedTimelines })
    }

    render() {
        const { timelines, period, contextReviewContent, newProjectContent} = this.state
        const { onHover } = this.props

        if (!timelines) return <ActivityIndicator busy />

        const mode = Settings.get("OVERVIEW_MODE_projects_mode");
        const classes = utils.createClassName("TimeReportOverview-timelines", {
        //   "noscroll": !!state.contextReviewContent || !!state.newProjectContent
        })

        return <div className="TimeReportOverview-Projects">
            <div className="container">
                <TopBar
                    mode={mode}
                    parentProps={this.props}
                    parentState={this.state}
                    onFilterToggle={this._onFilterToggle}
                    onModeToggle={this._onModeToggle}
                    onNewProject={this._onNewProject}
                />

                <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                    <Droppable droppableId="droppable">
                        {(droppableProvided, droppableSnapshot) => <div id="draggable-container" ref={droppableProvided.innerRef}>
                            <div className={classes}>
                                {timelines.map((timeline, index) => (
                                    <Draggable key={timeline.id} draggableId={timeline.id.toString()} index={index}>
                                        {(draggableProvided, draggableSnapshot) => <div
                                            className='draggable-timeline'
                                            ref={draggableProvided.innerRef}
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                            style={getItemStyle(
                                                draggableSnapshot.isDragging,
                                                draggableProvided.draggableProps.style,
                                                index === 0
                                            )}
                                        >
                                            <Timeline
                                                key={`Timeline-${index}`}
                                                index={index}
                                                period={period}
                                                data={timeline}
                                                mode={mode}
                                                view='projects'
                                                onHover = {onHover}
                                                onCellClick={this._onCellClick}
                                                onContextReviewClick={this._onContextReviewClick}
                                            />
                                        </div>}
                                    </Draggable>
                                ))}

                                {droppableProvided.placeholder}
                            </div>
                        </div>}
                    </Droppable>
                </DragDropContext>

                <Modal visible={!!contextReviewContent} onClosed={this._onContextReviewModalClosed.bind(this, false)}>
                    {contextReviewContent}
                </Modal>

                <Modal visible={!!newProjectContent} onClosed={this._onContextReviewModalClosed.bind(this, false)}>
                    {newProjectContent}
                </Modal>
            </div>
        </div>
    }

    // Internal methods

    _onContextReviewModalClosed = (fetch = true) => {
        document.getElementsByClassName("TimeReportOverview-Projects")[0].style.overflowY = "auto"
        this.setState({
            busy: fetch,
            contextReviewContent: null,
            newProjectContent: null
        }, async () => {
            if (fetch) {
                // Fetch and load time report data
                await this.props.fetchDataAsync()
                this.setState({ busy: false })
            }
        })
    }

    _onTimeReporterChange = async () => {
        await _refreshTimeReportData.call(this);
    }

    _onFilterToggle = () => {
        this.setState(prevState => ({ filterViewVisible: !prevState.filterViewVisible }));
    }

    _onModeToggle = () => {
        this.setState(prevState => ({ filterModeVisible: !prevState.filterModeVisible }));
    }

    _onNewProject = () => {
        document.getElementsByClassName("TimeReportOverview-Projects")[0].style.overflowY = "hidden"
        this.setState({
            newProjectContent: <ContextReviewContent writeable={ true } type="project" isNew onSave={ this._onContextReviewModalClosed }/>
        });
    }

    _onCellClick = (context) => {
        this.props.onHover && this.props.onHover(null)
        if (context.dataset && context.dataset.length === 0) return

        let dataset = context.dataset.filter(e => !e.placeholder && !e.shadow)
        // prevents reversing the already reversed dataset
        if (!context.sorted) dataset.reverse()
        context.sorted = true

        context.isCellClick = true
        context.dataset = dataset
        context.resourceid = context.dataset[0].resourceId
        context.projectid = context.dataset[0].projectId
        this.props.setTimeReportData(context)
    }

    _onContextReviewClick = (id, context) => {
        const { props } = this;
        // const { reducers } = props;
        const { user } = props;
        const isManager = user.roleType === "manager";

        //const isManager = reducers.app.user.roleType === "manager";
        document.getElementsByClassName("TimeReportOverview-Projects")[0].style.overflowY = "hidden"
        this.setState({
            contextReviewContent: (
                <ContextReviewContent
                    writeable={ isManager }
                    id={ id }
                    context={ context }
                    type="project"
                    onSave={ this._onContextReviewModalClosed }
                />
            )
        });
    }
}


// PRIVATE FUNCTIONS

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle, isFirstChild) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `10px 0`,
  ...draggableStyle,
});

// PRIVATE COMPONENTS
class TopBar extends React.PureComponent {

    render() {
        const { props } = this;
        const { parentProps, parentState, mode, onFilterToggle, onModeToggle, onNewProject } = props;
        const { user, onJump, onDateChange, onFilterChange } = parentProps;
        const { period, totalHours, totalFee, totalCost, extraCost, extraFee, totalSalary, overheadCost, overheadFee, filterViewVisible, filterModeVisible } = parentState;
        const isManager = user.roleType === "manager";

        return (
            <Div className="TimeReportOverview-top-bar">
                <div className="start">
                    <div className="period">
                        <DatePicker
                            type="range"
                            startDate={ utils.moment(period.startDate) }
                            endDate={ utils.moment(period.endDate) }
                            onDateChange={ onDateChange }
                            onJump={ onJump }
                        />
                    </div>

                    <Statistics
                        type="total"
                        data={{
                            overheadFee,
                            overheadCost,
                            totalHours,
                            totalFee,
                            totalCost,
                            totalSalary,
                            extraFee,
                            extraCost
                        }}

                        isManager={ isManager }
                        mode={ mode }
                        modeType={'projects_mode'}
                        filterModeVisible={ filterModeVisible }
                        
                        onModeToggle={ onModeToggle }
                        onFilterChange={ onFilterChange }
                    />
                </div>

                <div className="end">
                    <div className={ utils.createClassName("filter-container", { "opened": filterViewVisible }) }>
                        <StyledButton className={ utils.createClassName("button", { "opened": filterViewVisible }) } title={ i18n("general", "filter") } onClick={ onFilterToggle }>
                            <div className="icon material-icons">remove_red_eye</div>
                        </StyledButton>
                        <FilterView isManager={isManager} type="projects" visible={ filterViewVisible } onClose={ onFilterToggle } onFilterChange={ onFilterChange } />
                    </div>
                    { isManager && (
                        <StyledButton className="button" title={ i18n("timereport", "new_project") } onClick={ onNewProject }>
                            <div className="icon material-icons">add</div>
                        </StyledButton>
                    )}
                </div>
            </Div>
        )
    }
}


// PRIVATE FUNCTIONS
async function _refreshTimeReportData() {
    const { timeReporter } = this.props
    const period = timeReporter.getCurrentPeriod()
    const unreportedDates = timeReporter.getUnreportedDates("resources")
    const timelines = timeReporter.getTimelines("projects")
    let overheads = timeReporter.getOverheads()

    if (this._isMounted && timelines) {
        const { totalHours, totalFee, totalCost, totalSalary, extraFee, extraCost } = utils.getTimelineStatistics(timelines, period, "projects")
        const { overheadFee, overheadCost } = utils.getTotalOverhead(overheads, period, timeReporter)

        this.setState({
            period,
            timelines,
            unreportedDates,
            totalHours,
            totalFee,
            totalCost,
            totalSalary,
            extraFee,
            extraCost,
            overheadFee,
            overheadCost
        }, () => {
            // Open ReportTimeModal if there are unreported dates (and if first time)
            if (unreportedDates && unreportedDates.length > 0 && !this.state.hasOpenedReportTimeWindowOnce) {
                this.setState({ hasOpenedReportTimeWindowOnce: true }, () => this.props.setTimeReportData(unreportedDates[0]))
            }
        })
    }
}

const Div = styled.div`
    ${props => props.className === 'TimeReportOverview-top-bar' && css`
        background: ${props.theme.overlay};
    `}
    ${props => props.className === 'statistics' && css`
        border-left: 1px solid ${props.theme.background};
        @media (min-width: 640px) {
            &:hover {
                background: ${props.theme.background};
            }
        }
        // @media (max-width: 640px) {
        //     border-top: 1px solid ${props.theme.background} !important
        // }
    `}
    ${props => props.className === 'label' && css`
          color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('icon') && css`
          color: ${props.theme.label};
    `}

`;

const StyledButton = styled.div`
    color: ${props => props.theme.subtitle};
    &:hover {
        background: ${props => props.theme.background};
    }

    ${props => props.className.split(' ').includes('opened') && css`
        background: ${props.theme.overlay} !important;
    `}
`;
