import React,{ PureComponent } from "react";

// COMPONENTS
import StackLayout from "components/layouts/StackLayout/StackLayout";
import Button from "components/Button/Button";
import ImageUpload from "components/ImageUpload/ImageUpload";

import ReactCrop, { makeAspectCrop, containCrop } from "components/Crop/ReactCrop";
import ShowMessageBox from "components/MessageBox/MessageBox";

// HELPERS
import * as utils from "helpers/utils";
import api, { getAssetsURIForPath } from "helpers/api";
import i18n from "helpers/i18n";

export default class UploaderForm extends PureComponent {
    state = {
        crop: {
            x: 0,
            y: 0,
        },
        maxHeight: 100,
        preview: false
    }

    onImageLoaded = (image) => this.setState({
        crop: this.props.type !== "monitor" ? 
            makeAspectCrop({
                x: 0,
                y: 0,
                aspect: 1,
                height: 100,
            }, image.naturalWidth / image.naturalHeight)
            : containCrop({
                x: 0,
                y: 0,
                height: image.naturalHeight+4, //+4 to account for padding
                width: image.naturalWidth+4
            }, image.naturalWidth+4 / image.naturalHeight+4),

        disabled: false,
        naturalImageSize : {
            height: image.naturalHeight,
            width: image.naturalWidth
        }
    })

    onCropComplete = (crop, pixelCrop) => this.setState({ pixelCrop : pixelCrop, cropPercent : crop })
    
    onCropChange = (crop) => this.setState({ crop })

    onPreview = (file, data) => this.setState({
        willDelete: false,
        file: file,
        imagePreviewUrl: data,
        preview: true
    })

    handleSubmit = async (e) => {
        e.preventDefault()
        const { imagePreviewUrl, willDelete, pixelCrop, cropPercent, naturalImageSize } = this.state
        const { onUpload, id, type, uploadedImage } = this.props

        if (willDelete) {
            const data = {
                'ID': id,
                'type': type,
                'content': undefined
            }

            return onUpload?.(data)
        } else {
            if (!imagePreviewUrl) {
                return onUpload?.(uploadedImage)
            }

            const data = {
                pixelCrop: pixelCrop,
                cropPercent: cropPercent,
                naturalImageSize: naturalImageSize,
                imageBase64: imagePreviewUrl,
                type: type
            }

            const croppedImage = await api("/assets/cropImage", data)

            var dataresponse = {
                'ID': id,
                'type': type,
                'content': 'data:image/png;base64,' + croppedImage
            }

            return onUpload?.(dataresponse)
        }
    }

    onDelete = () => this.setState({
        file: undefined,
        imagePreviewUrl: undefined,
        preview: false,
        willDelete: true
    })

    handleInvalidImage() {
        ShowMessageBox(this, {
            message : i18n("errors", "invalidImage"),
            buttons : [{
                label: 'OK',
                appearance: 'primary',
                onClick: () => this.setState({ MessageBox: null, preview: false })
            }]
        })
    }

    render() {
        const { imagePreviewUrl, preview, willDelete, MessageBox } = this.state
        const { uploadedImage } = this.props
        const src = !willDelete ? (uploadedImage && uploadedImage.hasOwnProperty("content") ? uploadedImage.content : (uploadedImage ? getAssetsURIForPath(uploadedImage) : null)) : null
        const classes = utils.createClassName('avatarContainer')

        return (
            <div>
                {MessageBox}
                <div className="uploaderForm">
                    <div>
                        <StackLayout orientation="horizontal" style={{ marginLeft: "auto" }}>
                            <div className={classes}>
                                {!preview && <div className="uploaderForm">
                                    <ImageUpload src={src} onPreview={this.onPreview.bind(this)}/>
                                </div>}
                                {preview && <div className="avatar">
                                    <div>
                                        {!this.state.MessageBox && <ReactCrop
                                            {...this.state}
                                            src={imagePreviewUrl}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                            onError={this.handleInvalidImage}
                                        />}
                                    </div>
                                    <div id="crop-editor"></div>
                                </div>}
                            </div>
                        </StackLayout>
                    </div>
                    <center>
                        <div className={"icon material-icons deletePreview"} onClick={this.onDelete}>delete</div>
                        <StackLayout orientation="horizontal" style={{ marginLeft: "auto" }}>
                            <Button
                                className="filled"
                                type="submit"
                                label={i18n("warnings", "confirm")}
                                appearance="primary"
                                onClick={ this.handleSubmit }
                                filled
                            />
                        </StackLayout>
                    </center>
                </div>
            </div>
        )
    }
}
