import React from "react"

// CLASSES
import Settings from "classes/Settings"
import styled, {css} from 'styled-components'

// COMPONENTS
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"
import DatePicker from "components/DatePicker/DatePicker"
import Modal from "components/Modal/Modal"
import Form from "components/Form/Form"
import StackLayout from "components/layouts/StackLayout/StackLayout"
import Button from "components/Button/Button"
import ShadowCard from "components/ShadowCard/ShadowCard"
import CompanyCard from "components/CompanyCard/CompanyCard"
import TagCard from "components/TagCard/TagCard"
import ReminderCard from "components/ReminderCard/ReminderCard"
import PunchClockCard from "components/PunchClockCard/PunchClockCard.js"
import IntegrationCard from "components/IntegrationCard/IntegrationCard.js"
import ShowMessageBox from "components/MessageBox/MessageBox"
import UploaderForm from "../../../components/UploaderForm/UploaderForm"
import Tooltip from "components/Tooltip/Tooltip"
import Statistics from "components/Statistics/Statistics";

import ContextReviewContent from "../../../components/ContextReviewContent/ContextReviewContent"
import TagsReviewContent from "../../../components/TagsReviewContent/TagsReviewContent"
import PunchClockContent from "../../../components/PunchClockContent/PunchClockContent"
import FortnoxContent from "../../../components/FortnoxContent/FortnoxContent"
import FilterView from "../../../components/FilterView/FilterView"
import EditableTable from "../../../components/EditableTable/EditableTable"
import Reminders from "./GlobalViews/Reminders"
import SketchPicker from '@uiw/react-color-sketch'

// HELPERS
import * as utils from "helpers/utils"
import { createClassName } from "helpers/utils"
import api, { getAssetsURIForPath } from "helpers/api"

import i18n from "helpers/i18n"

export default class Globals extends React.PureComponent {
    state = {
        totalHours: 0,
        totalFee: 0,
        extraFee: 0,
        totalCost: 0,
        extraCost: 0,
        filterModeVisible: false,
        period: null,
        timelines: null,
        unreportedDates: null,
        hasOpenedReportTimeWindowOnce: true,
        filterViewVisible: false,
        contextReviewContent: null,
        newProjectContent: null,
        newCompanyContent: null,
        newTagsContent: null,
        tagsContent: null,
        remindersContent: null,
        punchClockContent: null,
        newPunchClockContent: null,
        integrationContent: null,
        subMode: Settings.get("GLOBAL_TAB") || 'shadows',
        inactiveSubMode: Settings.get("GLOBAL_ITAB") || 'users',
        busy: true,
        saved: false,
        sketchPickerVisible: false,
        colorpickerFor: undefined,
        tooltipdata: undefined,
        data: {},
        resetToDefault: false,
        uploaderForm: undefined,
        logoUploadData: undefined,

    }

    async componentDidMount() {
        this._isMounted = true
        document.getElementById("globals-nav").getElementsByClassName("active")[0].scrollIntoView()
        const { timeReporter } = this.props

        timeReporter.addListener("change", this._onTimeReporterChange)

        if (!timeReporter.getTimelines("shadows")) await this.props.fetchDataAsync()
        else _refreshTimeReportData.call(this)

        this._getGlobals()
    }

    componentWillUnmount() {
        this._isMounted = false
        const { timeReporter } = this.props
        timeReporter.removeListener("change", this._onTimeReporterChange)
        timeReporter.clearTimelines("shadows")
        Settings.set("GLOBAL_TAB", "")
        Settings.set("GLOBAL_ITAB", "")
    }

    _onTimeReporterChange = async () => await _refreshTimeReportData.call(this)

    _onFilterToggle = () => this.setState(prevState => ({ filterViewVisible: !prevState.filterViewVisible }))

    _onNewProject = () => {
        this.setState({
            newProjectContent: <ContextReviewContent writeable={ true } type="project" isShadow isNew onSave={ this._onContextReviewModalClosed }/>
        })
    }

    _onNewCompany = () => {
        this.setState({
            newCompanyContent: <ContextReviewContent writeable={ true } type="company" isNew onSave={ this._onContextReviewModalClosed }/>
        })
    }

    _onNewTag = () => {
        this.setState({
            tagsContent: <TagsReviewContent isNew onSaved = {this._onSavedHandler}/>
        })
    }

    _onNewPunchClock = () => {
        this.setState({
            punchClockContent: <PunchClockContent new onSaved = {this._onSavedHandler}/>
        })
    }

    _openFortnoxContent = () => {
        this.setState({
            integrationContent: <FortnoxContent data={ this.state.data.integrations.fortnox } onSaved={ this._onSavedHandler }/>
        })
    }

    _goToPunchClockBoard = () => window.location.href = "../punchclock/timereport"

    _onShowTagsHandler = (ID, name, data) => {
        this.setState({
            tagsContent: <TagsReviewContent ID={ID} title={name} data={data} onSaved={this._onSavedHandler}/>
        })
    }

    _onNewReminder = () => {
        this.setState({
            remindersContent: <Reminders isNew onSaved = {this._onSavedHandler} />
        })
    }

    _onShowRemindersHandler = (ID, title, message, reminder, recipients, rule, time) => {
        this.setState({
            remindersContent: <Reminders ID={ID} rule={rule} time={time} reminder={reminder} message={message} title={title} recipients={recipients} onSaved={this._onSavedHandler}/>
        })
    }

    _onShowPunchClockHandler = (data, name, code, id) => {
        this.setState({
            punchClockContent: <PunchClockContent ID={id} data={data} name={name} code={code} onSaved = {this._onSavedHandler}/>
        })
    }

    _onSavedHandler = () => {
        this.setState({
          tagsContent: null,
          punchClockContent: null,
          remindersContent: null,
          integrationContent: null
        }, () => this._getGlobals())
    }

    _onCloseTagsHandler = () => this.setState({ tagsContent: null })

    _onClosePunchClockHandler = () => this.setState({ punchClockContent: null })

    _onCloseIntegrationHandler = () => this.setState({ integrationContent: null })

    _onCloseRemindersHandler = () => {this.setState({ remindersContent: null })}

    _onContextReviewClick = (id, context, type) => {
        const isManager = this.props.user.roleType === "manager"

        this.setState({
            contextReviewContent: (
                <ContextReviewContent
                    writeable={isManager}
                    id={id}
                    context={context}
                    type={type}
                    onSave={this._onContextReviewModalClosed}
                />
            )
        })
    }

    _onContextReviewModalClosed = (fetch = true) => {
        this.setState({
            busy: fetch,
            contextReviewContent: null,
            newProjectContent: null,
            newCompanyContent: null,
            newTagsContent: null
        }, async () => {
            // Fetch and load time report data
            if (fetch) {
                await this.props.fetchDataAsync()
                const result = await api('globals', '')

                if (this._isMounted) this.setState({
                    busy: false,
                    data: result
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { busy } = this.state
        if (prevState.busy !== busy && busy) this._getGlobals()
    }

    _getGlobals = async () => {
        if (this._isMounted) this.setState({
            busy: true,
            contextReviewContent: null,
            newProjectContent: null,
            newCompanyContent: null,
            newTagsContent: null,
            newPunchClockContent: null
        }, async () => {
            const url = 'globals'
            const result = await api(url, '')

            if (this._isMounted) this.setState({
                busy: false,
                data: result
            })
        })
    }

    render() {
        const { subMode, inactiveSubMode, data, busy } = this.state
        const context = this

        return (
            <div className="TimeReportOverview-Globals" style={subMode === "globalcolors" ? {overflow: "visible"} : {}}>
                <div className="container">
                    <TopBar
                        parentProps={this.props}
                        parentState={this.state}
                        onFilterToggle={this._onFilterToggle}
                        onModeToggle={this._onModeToggle}
                        onNewResource={this._onNewResource}
                        onChange={this._onChange}
                        context={context.state}
                        />
                    {busy ? (<ActivityIndicator busy />)
                    : (<GlobalContent
                        getGlobals={this._getGlobals}
                        parentProps={this.props}
                        parentState={this.state}
                        onModeToggle={this._onModeToggle}
                        onFilterToggle={this._onFilterToggle}
                        onNewProject={this._onNewProject}
                        onNewCompany={this._onNewCompany}
                        onNewTag={this._onNewTag}
                        onNewPunchClock={this._onNewPunchClock}
                        openFortnoxContent={this._openFortnoxContent}
                        goToPunchClockBoard={this._goToPunchClockBoard}
                        onContextReviewClick={this._onContextReviewClick}
                        onContextReviewModalClosed={this._onContextReviewModalClosed.bind(this, false)}
                        onShowTagsHandler={this._onShowTagsHandler}
                        onShowRemindersHandler={this._onShowRemindersHandler}
                        onNewReminder={this._onNewReminder}
                        onShowPunchClockHandler={this._onShowPunchClockHandler}
                        onCloseTagsHandler={this._onCloseTagsHandler}
                        onCloseRemindersHandler={this._onCloseRemindersHandler}
                        onClosePunchClockHandler={this._onClosePunchClockHandler}
                        onCloseIntegrationHandler={this._onCloseIntegrationHandler}
                        data={data}
                        subMode={subMode}
                        inactiveSubMode={inactiveSubMode}
                        onInactiveTabChange={this._onInactiveTabChange}
                        context={context}
                        ref={this.colorRef}
                    />)}
                </div>
            </div>
        )
   }

    // Internal methods
    _onChange = (subMode) => {
        this.setState({ subMode, saved: true })
        Settings.set("GLOBAL_TAB", subMode)
    }

    _onInactiveTabChange = (inactiveSubMode) => {
        this.setState({ inactiveSubMode })
        Settings.set("GLOBAL_ITAB", inactiveSubMode)
    }

    _onModeToggle = () => this.setState(prevState => ({ filterModeVisible: !prevState.filterModeVisible }))
}

// PRIVATE COMPONENTS
class TopBar extends React.PureComponent {

    state = {
        subMode : Settings.get("GLOBAL_TAB") || 'shadows'
    }

    render() {

        const { state } = this
        const classes = createClassName("ContextReviewContent-EditToggler", {
            "active": true
        })

        return (
            <Div className="TimeReportOverview-top-bar" id='Globals-top-bar'>
                { this.props.context.MessageBox }
                <Div className={ classes }>
                    <EditToggler type={ 'type' } active={ true } subMode={ state.subMode } onChange={ this._changeModes } />
                </Div>
            </Div>
        )
    }

    _changeModes = (subMode, element) => {
        const { onChange } = this.props

        element.target.scrollIntoView()
        this.setState({ subMode })
        if(onChange) onChange(subMode)
    }

}

class ShadowTopBar extends React.PureComponent { // WIP
    render() {
        const { parentProps, parentState, onFilterToggle, onModeToggle, onNewProject, mode } = this.props
        const { onJump, onDateChange, onFilterChange, user } = parentProps
        const { period, filterViewVisible, filterModeVisible, overheadCost, overheadFee, totalHours, totalFee, extraFee, totalCost, extraCost, totalSalary } = parentState
        const isManager = user.roleType === "manager"

        return (
            <Div className="TimeReportOverview-top-bar global" id='shadows-top-bar'>
                <div className="start">
                    <div className="period">
                        <DatePicker
                            type="range"
                            startDate={ utils.moment(period.startDate) }
                            endDate={ utils.moment(period.endDate) }
                            onDateChange={ onDateChange }
                            onJump={ onJump }
                        />
                    </div>

                    <Statistics 
                        type="total"
                        data={{
                            overheadFee,
                            overheadCost,
                            totalHours,
                            totalFee,
                            totalCost,
                            totalSalary,
                            extraCost,
                            extraFee
                        }}

                        isManager={ isManager }
                        mode={ mode }
                        modeType={'shadows_mode'}
                        filterModeVisible={ filterModeVisible }
                        
                        onModeToggle={ onModeToggle }
                        onFilterChange={ onFilterChange }
                    />
                </div>

                <div className="end">
                    <div className={ utils.createClassName("filter-container", { "opened": filterViewVisible }) }>
                        <StyledButton className={ utils.createClassName("button", { "opened": filterViewVisible }) } title={ i18n("general", "filter") } onClick={ onFilterToggle }>
                            <div className="icon material-icons">remove_red_eye</div>
                        </StyledButton>
                        <FilterView isManager={user.roleType === 'manager'} type="shadows" visible={ filterViewVisible } onClose={ onFilterToggle } onFilterChange={ onFilterChange } />
                    </div>
                    <StyledButton className="button" onClick={ onNewProject }>
                        <div className="icon material-icons">add</div>
                    </StyledButton>
                </div>
            </Div>
        )
    }
}

class ColorTopBar extends React.PureComponent {
    preview = () => {
        const { origin, pathname } = window.location
        window.open(origin + pathname + "?view=resources&mode=preview")
    }

    render() {
        return (
            <Div className="TimeReportOverview-top-bar global">
                <div className="start"></div>
                <div className="end">
                    <StyledButton className="button" onClick={ this.preview }>
                        <div className="icon material-icons">add</div>
                    </StyledButton>
                </div>
            </Div>
        )
    }
}

class ReminderTopBar extends React.PureComponent {
    render() {
        return (
            <Div className="TimeReportOverview-top-bar global" id='secondary-top-bar'>
                <div className="start"></div>
                <div className="end">
                    <StyledButton className="button" onClick={ this.props.onNewReminder }>
                        <div className="icon material-icons">add</div>
                    </StyledButton>
                </div>
            </Div>
        )
    }
}
class TagTopBar extends React.PureComponent {
    render() {
        const { onNewTag, onNewCompany } = this.props

        return (
            <Div className="TimeReportOverview-top-bar global" id='secondary-top-bar'>
                <div className="start"></div>
                <div className="end">
                    <StyledButton className="button" onClick={ onNewTag ? onNewTag : onNewCompany }>
                        <div className="icon material-icons">add</div>
                    </StyledButton>
                </div>
            </Div>
        )
    }
}

class PunchClockTopBar extends React.PureComponent {
    render() {
        const { onNewPunchClock, goToPunchClockBoard } = this.props

        return (
            <Div className="TimeReportOverview-top-bar global" id="Punchclock-top-bar">
                <div className="start"/>

                <div className="end">
                    <StyledButton className="button" onClick={ goToPunchClockBoard }>
                        <div className="icon material-icons">timer</div>
                    </StyledButton>

                    <StyledButton className="button" onClick={ onNewPunchClock }>
                        <div className="icon material-icons">add</div>
                    </StyledButton>
                </div>
            </Div>
        )
    }
}

class IntegrationTopBar extends React.PureComponent {
    render() {

        return (
            <Div className="TimeReportOverview-top-bar global" id="integration-top-bar"></Div>
        )
    }
}

class InactiveTopBar extends React.PureComponent {
    render() {
        const { inactiveSubMode, onInactiveTabChange } = this.props

        return (
            <Div className="TimeReportOverview-top-bar global" id='inactive-top-bar'>
                <div>
                    <SubModeButton className={`subModeButton ${inactiveSubMode === 'users' ? 'active' : ''}`} onClick={ () => onInactiveTabChange('users') }>
                        {i18n('globals', 'users')}
                    </SubModeButton>
                    <SubModeButton className={`subModeButton ${inactiveSubMode === 'projects' ? 'active' : ''}`} onClick={ () => onInactiveTabChange('projects') }>
                        {i18n('globals', 'projects')}
                    </SubModeButton>
                </div>
            </Div>
        )
    }
}

const EmptyTopBar = () => <Div className="TimeReportOverview-top-bar global" id='empty-top-bar'/>

class EditToggler extends React.PureComponent {

    render() {
        const { props } = this
        const { active } = props
        const classes = createClassName("ContextReviewContent-EditToggler", {
            "active": active,
        })

        return (
            <Div className={ classes } >
                <EditSubModeNav { ...props } />
            </Div>
        )
    }
}

class GlobalContent extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            ...this.props
        }
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick, false)
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
        document.removeEventListener('click', this.handleOutsideClick, false)
    }

    handleOutsideClick(e){
        if(!this.colorRef) return
        if(this.colorRef.contains(e.target)){
          return
        }
        this.setState({
            sketchPickerVisible: false,
        })

    }

    _onColorpickerClick = (tag) => {
        this.setState({
                        colorpickerFor: tag,
                        sketchPickerVisible: tag === this.state.colorpickerFor ? !this.state.sketchPickerVisible : true,
                        tooltipdata: undefined
        })
    }

    _onColorChange = (tag,color) => {
        const { globalcolors } = this.state.data
        globalcolors[tag] = color.hex
        Settings.setPreviewGlobalColor(tag, color)
        this.setState({
          data: {
            ...this.state.data,
            globalcolors: globalcolors
          },
          resetToDefault: false,

        })

    }

    _onShowUpload = () => {
        this.setState({
            uploaderForm: <UploaderForm type = "avatar" id = { 0 } uploadedImage = { this.state.logoUploadData ? this.state.logoUploadData : ''} onUpload = { this._onUpload } />
        })
    }

    _onCloseUpload = () => {
        this.setState({
            uploaderForm: undefined
        })
    }

    _onUpload = (value) => {
        this.setState({
          logoUploadData: value,
          uploaderForm: null,
          resetToDefault: false
        })
    }

    _onHover = (e,tag) => {
        if(this.state.sketchPickerVisible) return
        const x = e.clientX
        const y = e.clientY

        const data = {
          globalcolorpreview: tag,
          x: x,
          y: y
        }
        this.setState({tooltipdata: data})

    }

    _onInactiveTabChange = (mode) => {
        if (this.props.onInactiveTabChange) this.props.onInactiveTabChange(mode)
    }

    _hideTooltip = () => {
        this.setState({tooltipdata: undefined})
    }

    _onResetToDefault = async () => {
        const defaultColors = await api('/globals/colors/getDefaults', {})
        Settings.setPreviewGlobalColors(defaultColors)
        if (this._isMounted) this.setState({
          data: {
            ...this.state.data,
            globalcolors: defaultColors
          },
          resetToDefault: true
        })
    }

    _onFormSubmit = async (formData) => {
        const { state, props } = this, { resetToDefault, logoUploadData } = state, { subMode, getGlobals } = props
        let result = ''

        if (resetToDefault) {
            result = await api('/globals/colors/resetToDefault', {})
        } else {
            const url = subMode === 'overhead'      ? 'globals/overhead/save' :
                        subMode === 'globalcolors'  ? 'globals/colors/save' : ''

            const data = { data: subMode === 'overhead' ? state.data.overhead :
                                 subMode === 'globalcolors' ? state.data.globalcolors : '' }

            result = await api(url, data)
        }

        if (result.success) {
            if (logoUploadData && subMode === 'globalcolors' ) {
                const data = {content: logoUploadData.content}
                await api('/globals/savelogo', data)
            }

            if (this._isMounted) this.setState({ saved: true }, () => {
                setTimeout(async () => {
                    if (this._isMounted) getGlobals()
                    if (subMode  === 'globalcolors') window.location.reload()
                }, 1000)
            })
        } else {
            if (!result.success) {
                ShowMessageBox(props.context, {
                    message : result.message,
                })
            }
        }

    }
    render() {
        const { props, state } = this
        const { data, saved } = state

        const { subMode, inactiveSubMode, parentState, parentProps, onFilterToggle, onModeToggle, onNewProject, onNewCompany, onNewTag, onPreviewStart, onNewPunchClock, goToPunchClockBoard, openFortnoxContent, onContextReviewClick, onContextReviewModalClosed, onNewReminder } = props
        const mode = Settings.get("OVERVIEW_MODE_shadows_mode")
        if(!parentState.timelines) return <ActivityIndicator busy />

        let uniquetimelines = []
        let uniqueids = []

        parentState.timelines.forEach(timeline => {
            if (!uniqueids.includes(timeline.id)){
                uniqueids.push(timeline.id)
                uniquetimelines.push(timeline)
            }
        })
        parentState.timelines = uniquetimelines

        return (
            <React.Fragment>
                <React.Fragment>
                    { subMode === "overhead" ? (
                        <React.Fragment>
                            <EmptyTopBar />
                            <Form onSubmit={ this._onFormSubmit } autoComplete={ false } style={{ background: Settings.getGlobalColor('overlay') }} global>
                                <EditableTable
                                    context={ this }
                                    type={ "overhead" }
                                    rows={ data.overhead }
                                    columns={[
                                        { key: "selected", label: "", width: "5%" },
                                        { name: "StartDate", label: "Start Date", width: "15%" },
                                        { name: "EndDate", label: "End Date", width: "15%" },
                                        { name: "Fee", label: "Fee", width: "10%" },
                                        { name: "Cost", label: "Cost", width: "10%" },
                                        { name: "Label", label: "Label", width: "20%" },
                                    ]}
                                    onRowChecked={ this._onRowChecked.bind(this) }
                                    onRemoveSelected={ this._onRemoveSelected.bind(this) }
                                    onAddRow={ this._onAddRow.bind(this) }
                                />
                                <StackLayout orientation="horizontal" style={{ marginLeft: "auto" }}>
                                    <Button success = {saved} className = { createClassName("filled", { "success": saved }) } type="submit" label={ i18n("general", "save") } appearance="primary" />
                                </StackLayout>
                            </Form>
                        </React.Fragment>
                    ) : subMode === "shadows" ? (
                        <React.Fragment>
                            <ShadowTopBar
                                mode = {mode}
                                parentProps={ parentProps }
                                parentState={ parentState }
                                onFilterToggle={ onFilterToggle }
                                onModeToggle={ onModeToggle }
                                onNewProject={ onNewProject }/>
                            <div className='shadows-container'>
                                {parentState.timelines.map((shadow) => (shadow.context.shadow ?
                                    <ShadowCard
                                        key={`${shadow.id}`}
                                        mode={mode}
                                        data={shadow}
                                        period={parentState.period}
                                        onContextReviewClick={onContextReviewClick}
                                    /> : ''
                                ))}
                            </div>

                            <Modal visible={!!parentState.contextReviewContent} onClosed={onContextReviewModalClosed}>
                                {parentState.contextReviewContent}
                            </Modal>

                            <Modal visible={!!parentState.newProjectContent} onClosed={onContextReviewModalClosed}>
                                {parentState.newProjectContent}
                            </Modal>


                        </React.Fragment>
                    ) : subMode === "inactive" ? (
                        <React.Fragment>
                            <InactiveTopBar
                                inactiveSubMode={ inactiveSubMode }
                                onInactiveTabChange={ this._onInactiveTabChange }
                                parentState={ parentState } />
                            {inactiveSubMode === 'users' && <div className='inactive-container'>
                                {data.inactive.users.map((user) => (
                                    <InactiveDiv key={`${user.ID}`} className='inactive-row' onClick={() => onContextReviewClick(user.ID, user, 'resource')}>
                                        <img src={getAssetsURIForPath(user.avatarPath)} alt="" />
                                        <p>{user.firstName} {user.lastName}</p>
                                    </InactiveDiv>
                                ))}
                            </div>}
                            {inactiveSubMode === 'projects' && <div className='inactive-container'>
                                {data.inactive.projects.map((project) => (
                                    <InactiveDiv key={`${project.ID}`} className='inactive-row' onClick={() => onContextReviewClick(project.ID, project, 'project')}>
                                        <img src={getAssetsURIForPath(project.avatarPath)} alt="" />
                                        <p>{project.name}</p>
                                    </InactiveDiv>
                                ))}
                            </div>}

                            <Modal visible={!!parentState.contextReviewContent} onClosed={onContextReviewModalClosed}>
                                {parentState.contextReviewContent}
                            </Modal>

                            <Modal visible={!!parentState.newProjectContent} onClosed={onContextReviewModalClosed}>
                                {parentState.newProjectContent}
                            </Modal>
                        </React.Fragment>
                    ) : subMode === "companies" ? (
                        <React.Fragment>
                            <TagTopBar
                                parentProps={ parentProps }
                                parentState={ parentState }
                                onNewCompany={ onNewCompany }/>
                            <div className='companies-container'>
                                {data.companies.map((company) => (
                                    <CompanyCard
                                        key={`${company.ID}`}
                                        mode={mode}
                                        data={company}
                                        onContextReviewClick={onContextReviewClick}
                                    />
                                ))}
                            </div>

                            <Modal visible={!!parentState.contextReviewContent} onClosed={onContextReviewModalClosed}>
                                {parentState.contextReviewContent}
                            </Modal>

                            <Modal visible={!!parentState.newCompanyContent} onClosed={onContextReviewModalClosed}>
                                {parentState.newCompanyContent}
                            </Modal>
                        </React.Fragment>
                    ) : subMode === "tags" ? (
                        <React.Fragment>
                            <TagTopBar
                                parentProps={ parentProps }
                                parentState={ parentState }
                                onFilterToggle={ onFilterToggle }
                                onNewTag={ onNewTag }/>
                            <div className='tags-container'>
                                {data.tags.map((tag, i) => (
                                    <TagCard
                                        key={i}
                                        ID={tag.ID}
                                        name={tag.Name}
                                        data={tag.data}
                                        onClick= {this.props.onShowTagsHandler}
                                    />
                                ))}
                            </div>

                            <Modal  visible={ parentState.tagsContent != null }
                                    onClosed={ this.props.onCloseTagsHandler }>
                                { parentState.tagsContent }
                            </Modal>

                        </React.Fragment>
                    ) : subMode === "globalcolors" ? (
                      <React.Fragment>
                        <ColorTopBar 
                            preview={onPreviewStart}
                        />
                        <Div className='globalcolors'>
                          <div className='globalcolors-container' style={{width: '265px', }}>
                              <Div className='logo' onClick={this._onShowUpload} style={{ backgroundImage: `url(${ state.logoUploadData ? state.logoUploadData.content : getAssetsURIForPath('/logo.png') })`, borderRadius: '0px', marginBotton: '20px' }}/>
                              <div ref={colorRef => this.colorRef = colorRef}>
                              {Object.keys(data.globalcolors).map((tag,i) => (
                                  <div  key={i}
                                        className='globalcolor' style={{marginBottom: tag !== 'primary' && tag !== 'sidebar_icon' ? '4px': '10%', width: '100%', fontSize:'14px', cursor: 'pointer'}}
                                        onMouseOver={(e) => this._onHover(e,tag) }
                                        onMouseOut = {() => this._hideTooltip()}

                                        >
                                      <div className="globalcolorpreview" style={{
                                            background: data.globalcolors[tag], width:'20px', minWidth: '20px'}}
                                            onClick={() => this._onColorpickerClick(tag)}
                                            />

                                      <div style={{lineHeight:'20px'}}>{ i18n('global_colors', tag) }</div>

                                      {tag === state.colorpickerFor && state.sketchPickerVisible && (
                                        <Div className='color_picker' style={{  display: 'block',
                                                                                position: 'absolute',
                                                                                transform: 'translate(-100%,-50%)',
                                                                                zIndex: 999
                                                                              }}>
                                            <SketchPicker
                                                disableAlpha={true}
                                                color={data.globalcolors[tag]}
                                                onChange={ (color) => this._onColorChange(tag,color)}
                                                />
                                        </Div>
                                      )}

                                  </div>

                              ))}
                              <center>
                              <p/>
                              <Button onClick={this._onResetToDefault} label={i18n('globals','reset_to_default')} appearance="primary" size="small"> </Button>
                              {/* <a href="#" onClick={this._onResetToDefault}>{i18n('globals','reset_to_default')}</a> */}
                              </center>
                              <Button success = {saved}
                                      style={{margin: '0 auto', marginTop: '10%'}}
                                      className = { createClassName("filled", { "success": saved }) }
                                      type="submit" label={ i18n("general", "save") } appearance="primary"
                                      onClick={this._onFormSubmit}
                                      />
                              </div>

                          </div>
                        </Div>

                        <Modal visible={ !!state.uploaderForm } onClosed ={ this._onCloseUpload } >
                            {state.uploaderForm ? state.uploaderForm : ''}
                        </Modal>
                        <Modal  visible={ parentState.previewContent != null }
                                    onClosed={ this.props.onClosePreview }>
                                { parentState.previewContent }
                        </Modal>
                      </React.Fragment>
                    ) :
                    subMode === "reminders" ? (
                        <React.Fragment>
                            <ReminderTopBar
                                parentProps={ parentProps }
                                parentState={ parentState }
                                onNewReminder={ onNewReminder }/>
                            <div className='reminders-container'>
                                {data.reminders.map((reminder, i) => {
                                    return <ReminderCard
                                        key={i}
                                        ID={reminder.id}
                                        data={reminder}
                                        onClick= {this.props.onShowRemindersHandler}
                                    />
                                })}
                            </div>
                            <Modal  visible={ parentState.remindersContent != null }
                                onClosed={ this.props.onCloseRemindersHandler }>
                                { parentState.remindersContent }
                            </Modal>
                        </React.Fragment>
                    )   :
                    subMode === "punch_clocks" ? (
                        <React.Fragment>
                            <PunchClockTopBar
                                parentProps={ parentProps }
                                parentState={ parentState }
                                onNewPunchClock= {onNewPunchClock}
                                goToPunchClockBoard= {goToPunchClockBoard} />

                            <div className='tags-container'>
                                {Object.keys(data.punchclocks).map((i) => (
                                    <PunchClockCard
                                        key={i}
                                        data={data.punchclocks[i]}
                                        onClick= {this.props.onShowPunchClockHandler}
                                    />
                                ))}
                            </div>
                            <Modal  visible={ parentState.punchClockContent != null }
                                    onClosed={ this.props.onClosePunchClockHandler }>
                                { parentState.punchClockContent }
                            </Modal>

                        </React.Fragment>
                    ) :
                    subMode === "integrations" ? (
                        <React.Fragment>
                            <IntegrationTopBar />
                            <div className='tags-container'>
                                <IntegrationCard
                                    name={"Fortnox"}
                                    data={ data.integrations.fortnox }
                                    onClick={ openFortnoxContent }
                                />

                            </div>

                            <Modal visible={ parentState.integrationContent != null }
                                    onClosed={ this.props.onCloseIntegrationHandler }>
                                { parentState.integrationContent }
                            </Modal>
                        </React.Fragment>
                    ) : null }

                </React.Fragment>
                <Tooltip data={state.tooltipdata} />
            </React.Fragment>
        )
    }

    _onRowChecked = (type, colKey, rowIndex, checked) => {
        const rows = [...this.state.data[type]]
        rows[rowIndex][colKey] = checked

        this.setState({
          data: {
            ...this.state.data,
            [type]: rows
          }
        })
    }

    _onRemoveSelected = (type) => {
        const rows = [...this.state.data[type]].map(row => {
            if (row.selected) return {...row, delete: true }
            else return {...row}
        })

        this.setState({
            data: {
                ...this.state.data,
                [type]: rows
            }
        })
    }

    _onAddRow = (type) => {
        let rows = [...this.state.data[type]]

        if (type === 'overhead') {
            rows.unshift({
                isNew: true,
                selected: false,
                StartDate: utils.moment().format("YYYY-MM-01"),
                EndDate: null,
                Fee: '',
                Cost: '',
                Label: '',
            })
        }

        this.setState({
          data: {
            ...this.state.data,
            [type]: rows
          }
        })

    }

}

function EditSubModeNav({ type, active, subMode: activeSubMode, onChange }) {
    const Item = ({ label, subMode}) => {
        return (
            <Div className={ createClassName("item", { "active": activeSubMode === subMode }) } onClick={ onChange && ((e) => onChange(subMode, e)) } >{ label }</Div>
        )
    }

    return (
      <Div className="togglerShadow">
        <nav id="globals-nav">
          <Item label={ i18n("globals", "shadows") }      subMode="shadows" />
          <Item label={ i18n("globals", "inactive") }     subMode="inactive" />
          <Item label={ i18n("globals", "companies") }    subMode="companies" />
          <Item label={ i18n("globals", "tags") }         subMode="tags" />
          <Item label={ i18n("globals", "overhead") }     subMode="overhead" />
          <Item label={ i18n("globals", "globalcolors") } subMode="globalcolors" />
          <Item label={ i18n("globals", "emailreminder") }subMode="reminders" />
          <Item label={ i18n("globals", "punch_clock") }  subMode="punch_clocks" />
          <Item label={ i18n("globals", "integrations") }  subMode="integrations" />
        </nav>
      </Div>
    )
}



async function _refreshTimeReportData() {
    const { timeReporter } = this.props
    const period = timeReporter.getCurrentPeriod()
    const unreportedDates = timeReporter.getUnreportedDates()
    const timelines = timeReporter.getTimelines("shadows")
    let overheads = timeReporter.getOverheads()

    if (this._isMounted && timelines) {
        const { totalHours, totalFee, totalCost, totalSalary, extraFee, extraCost } = utils.getTimelineStatistics(timelines, period, "shadows")
        const { overheadFee, overheadCost } = utils.getTotalOverhead(overheads, period, timeReporter)
        this.setState({
            period,
            timelines,
            unreportedDates,
            totalHours,
            totalFee,
            totalCost,
            totalSalary,
            overheadFee,
            overheadCost,
            extraFee,
            extraCost
        }, () => {
            // Open ReportTimeModal if there are unreported dates (and if first time)
            if (unreportedDates && unreportedDates.length > 0 && !this.state.hasOpenedReportTimeWindowOnce) {
                this.setState({ hasOpenedReportTimeWindowOnce: true }, () => this.props.setTimeReportData(unreportedDates[0]))
            }
        })
    }
}


const Div = styled.div`

    ${props => props.className.split(' ').includes('TimeReportOverview-top-bar') && css`
        background: ${props.theme.overlay};
    `}
    ${props => props.className.split(' ').includes('top') && css`
        border-bottom: 1px solid ${props.theme.background};
    `}
    ${props => props.className.split(' ').includes('avatar') && css`
        background: ${props.theme.label} 50% 50% no-repeat;
    `}

    ${props => props.className.split(' ').includes('item') && css`
        color: ${props.theme.label};
    `}

    ${props =>  props.className.split(' ').includes('item') &&
                props.className.split(' ').includes('active')
                && css`
        color: ${props.theme.primary};
    `}

    ${props => props.className.split(' ').includes('togglerShadow') && css`
        background: ${props.theme.background};
        :hover {
            background: ${props.theme.background};
        }

        &::-webkit-scrollbar-thumb {
            background: ${props.theme.background};
            border-radius: 20px;
        }

        &::-webkit-scrollbar-track {
            background: ${props.theme.label}80;
            border-radius: 20px;
        }

        &::-webkit-scrollbar {
            height: 4px;
        }
    `}

    ${props => props.active && css`
        background: ${props.theme.primary};
        &:hover {
            background: ${props.theme.primary};
        }
    `}

    ${props => props.className.split(' ').includes('label') && css`
        color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('icon') && css`
        color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('text') && css`
        color: ${props.theme.subtitle};
    `}
    ${props => props.className.split(' ').includes('color_picker') && css`
        @media (max-width: 640px) {
            transform: translate(0,8%) !important;
        }
    `}

`

const StyledButton = styled.div`
    color: ${props => props.theme.subtitle};
    &:hover {
        background: ${props => props.theme.background};
    }

    ${props => props.className.split(' ').includes('opened') && css`
        background: ${props.theme.overlay} !important;
    `}
`

const SubModeButton = styled.div`
    color: ${props => props.theme.label};
    &:hover:not(.active) {
        color: ${props => props.theme.subtitle};
    }

    ${props => props.className.split(' ').includes('active') && css`
        color: ${props.theme.primary};
    `}
`

const InactiveDiv = styled.div`
    background: ${props => props.theme.overlay};
`