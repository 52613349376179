import React from "react"

// COMPONENTS
import ReactDatePicker, {registerLocale} from "react-datepicker"
import Timejumper from "components/Timejumper/Timejumper"

import styled, {css} from 'styled-components'

// HELPERS
import * as utils from "helpers/utils"
import i18n from "helpers/i18n"

import "react-datepicker/dist/react-datepicker.css"
import {enGB} from "date-fns/locale"
import { isValid as isValidDate, parse as toDate } from "date-fns"
registerLocale("en-GB", enGB)

export default class DatePicker extends React.Component {
    state = {
        focused: false,
        date: this.props.type === 'range' ? utils.moment(this.props.startDate) : this.props.date,
        dateValue: this.props.type !== 'range' && this.props.date ? this.props.date.format('YYYY-MM-DD') : '',
        // endDate: this.props.type === "range" && this.props.endDate ? utils.moment(this.props.endDate) : null,
        endDate: utils.moment(this.props.endDate),
        overhead: this.props.overhead,
        placeholder: this.props.placeholder
    }

    componentDidMount() {
        const { props, state } = this;
        const { type } = props;

        if(type === "range" && !state.endDate) {
            this.setState(previousState => ({
                endDate: utils.moment(previousState.date).add(1, "days")
            }));
        }
    }

    componentDidUpdate() {
        const { props, state } = this, { type, date } = props
        if (type !== 'range' && state.date !== date) {
            this.setState({ date, dateValue: date ? date.format('YYYY-MM-DD') : '' })
        }
    }

    render() {
        const { props, state } = this;
        const { type, markedPeriods, rowID, overhead, label, messageBox } = props;
        const classes = utils.createClassName(props.className, {
            "DatePicker": true,
            "focus": state.focused,
            'highlighted': markedPeriods && rowID && markedPeriods.includes(rowID),
            "range": type === 'range',
            "overhead": overhead,
            "messageBox": messageBox
        })

        const popperPlacement = "bottom-start"

        let datePicker = null;
        if (type === "range") {
            const customInput = (
                <div>
                    <Div className="label">{ i18n("general", type === "range" ? "start_date" : "date") }</Div>
                    <Div className="date">{ state.date.format("YYYY-MM-DD") }</Div>
                </div>
            );

            const customInputEndDate = (
                <div>
                    <Div className="label">{ i18n("general", "end_date") }</Div>
                    <Div className="date">{ state.endDate.format("YYYY-MM-DD") }</Div>
                </div>
            );

            datePicker = [(
                <ReactDatePicker
                    key="DatePicker-startDate"
                    className="DatePicker-button"
                    popperPlacement={popperPlacement}
                    customInput={ customInput }
                    selected={ state.date.toDate() }
                    startDate={ state.date.toDate() }
                    endDate={ state.endDate.toDate() }
                    onFocus={ this._onFocus }
                    onBlur={ this._onBlur }
                    onChange={ this._handleStartDateChange }
                    shouldCloseOnSelect={ false }
                    disabledKeyboardNavigation
                    locale="en-GB"
                    dateFormat='YYYY-MM-DD'
                    showWeekNumbers
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                          boundariesElement: "viewport"
                        },
                      }}
                />
            ), (
                <ReactDatePicker
                    key="DatePicker-endDate"
                    className="DatePicker-button DatePicker-buttonEndDate"
                    popperPlacement={popperPlacement}
                    customInput={ customInputEndDate }
                    selected={ state.endDate.toDate() }
                    startDate={ state.date.toDate() }
                    endDate={ state.endDate.toDate() }
                    onFocus={ this._onFocus }
                    onBlur={ this._onBlur }
                    onChange={ this._handleEndDateChange }
                    shouldCloseOnSelect={ false }
                    disabledKeyboardNavigation
                    locale="en-GB"
                    dateFormat='YYYY-MM-DD'
                    showWeekNumbers
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                          boundariesElement: "viewport"
                        },
                      }}
                />
            )];

        } else {
            const customInput = (
                <div>
                    <Div className="label">{ label }</Div>
                    <Div className="date">{ state.date ? state.date.format("YYYY-MM-DD") : "" }</Div>
                </div>
            );

            datePicker = (
                <ReactDatePicker
                    placeholderText={ state.placeholder }
                    customInput={label ? customInput : null}
                    popperPlacement={popperPlacement}
                    onFocus={ this._onFocus }
                    onCalendarClose={ this._onBlur }
                    selected={ this._isValidDateString(state.dateValue) ? state.dateValue : null }
                    onSelect={ this._handleDateChange }
                    onChangeRaw={ this._handleRawDateChange }
                    disabledKeyboardNavigation
                    locale="en-GB"
                    value={state.dateValue}
                    dateFormat='yyyy-MM-dd'
                    showWeekNumbers
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperModifiers={{
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "viewport"
                        },
                    }}
                />
            );
        }
        const timejumperAvailable = type === 'range' ? willShowTimeJumper(state.date, state.endDate) : false;

        return (
            <Div className={ classes } type={type}>
                {type === 'range' ? <React.Fragment>
                    <div className="DatePicker-overlay" />
                    { type === "range" && timejumperAvailable && (<Timejumper Previous onClick= { this._onJump }/>) }
                    { datePicker }
                    { type === "range" && timejumperAvailable && (<Timejumper Next onClick= { this._onJump } />) }
                </React.Fragment>
                : <React.Fragment>
                    { datePicker }
                </React.Fragment>}
            </Div>
        );
    }

    // Internal methods
    _onFocus = () => {
        this.setState({ focused: true })
    }

    _onBlur = () => {
        this.setState({
            focused: false
        }, () => {
            const dateChanged = this.state.dateValue !== this.state.date?.format('YYYY-MM-DD')

            if (dateChanged) {
                if (!this.state.dateValue || this._isValidDateString(this.state.dateValue)) {
                    this._handleDateChange(this.state.dateValue)
                } else {
                    this.setState({dateValue: this.state.date?.format("YYYY-MM-DD") || ''})
                }
            }
        })
    }

    _isValidDateString = (dateString) => {
        const date = toDate(dateString, "yyyy-MM-dd", new Date())
        const isDateString = dateString.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)

        return isDateString && isValidDate(date)
    }

    _onJump = (direction) => {

        const { onJump } = this.props;
        const { startDate, endDate } = this.props;

        var newPeriod = getNewPeriodFromJumper(startDate, endDate, direction);
        this.setState({
          date: newPeriod.startDate,
          endDate: newPeriod.endDate
        });

        if(onJump) onJump(newPeriod.startDate, newPeriod.endDate);

    }

    _handleRawDateChange = ({target}) => {
        const { value } = target;
        this.setState({ dateValue: value })
    }

    _handleDateChange = (date) => {
        const { onDateChange, monitor, name } = this.props
        date = date
            ? utils.moment(date)
            : null

        this.setState({
            date,
            dateValue: date
                ? date.format('YYYY-MM-DD')
                : '' 
        })

        if (monitor) onDateChange?.(date ? date.format('YYYY-MM-DD') : '', name)
        else onDateChange?.({ target: { value: date ? date.format('YYYY-MM-DD') : '' } })
    }

    _handleStartDateChange = (date) => {
        const { onDateChange } = this.props
        date = utils.moment(date)

        this.setState(prevState => {
            const endDate = date.diff(prevState.endDate) > 0
                ? utils.moment(date)
                : utils.moment(prevState.endDate)

            onDateChange?.(date, endDate)

            return {
                date,
                endDate
            }
        })
    }

    _handleEndDateChange = (endDate) => {
        const { onDateChange } = this.props
        endDate = utils.moment(endDate)

        this.setState(prevState => {
            const date = endDate.diff(prevState.date) < 0
                ? utils.moment(endDate)
                : utils.moment(prevState.date)

            onDateChange?.(date, endDate)

            return {
                endDate,
                date
            }
        })
    }
}

function willShowTimeJumper(startDate, endDate) {
    return getNewPeriodFromJumper(startDate, endDate, 'next') != null
}

function getNewPeriodFromJumper(startDate, endDate, direction) {
    let willShow = false
    let newStartDate = utils.moment(startDate)
    let newEndDate = utils.moment(endDate)

    let startWeekDay = startDate.isoWeekday()
    let endWeekDay = endDate.isoWeekday()

    const nDays = endDate.diff(startDate, 'days')
    // One day
    if (nDays === 0) {
        direction === 'next' ? newStartDate.add(1, 'days') : newStartDate.subtract(1, 'days')
        direction === 'next' ? newEndDate.add(1, 'days')   : newEndDate.subtract(1, 'days')
        willShow = true
    }

    // One workweek
    if (nDays === 4 && (startWeekDay === 1 && endWeekDay === 5)) {
        direction === 'next' ? newStartDate.add(1, 'week') : newStartDate.subtract(1, 'week')
        direction === 'next' ? newEndDate.add(1, 'week')   : newEndDate.subtract(1, 'week')
        willShow = true
    }

    //One week
    if (nDays === 6) {
        direction === 'next' ? newStartDate.add(1, 'week') : newStartDate.subtract(1, 'week')
        direction === 'next' ? newEndDate.add(1, 'week')   : newEndDate.subtract(1, 'week')
        willShow = true
    }

    // Quarter
    let firstDayQuarter = utils.moment(startDate).startOf("quarter")
    let lastDayQuarter = utils.moment(startDate).endOf("quarter")
    if (startDate.format("YYYY-MM-DD") === firstDayQuarter.format("YYYY-MM-DD") &&
        endDate.format("YYYY-MM-DD")   === lastDayQuarter.format("YYYY-MM-DD"))
    {
        direction === 'next'
            ? newStartDate.add(1, 'quarter')
            : newStartDate.subtract(1, 'quarter')
        direction === 'next'
            ? newEndDate.add(1, 'quarter').endOf("quarter")
            : newEndDate.subtract(1, 'quarter').endOf("quarter")
        willShow = true
    }

    // Year
    let firstDayThisYear = utils.moment(startDate).startOf("year")
    let lastDayThisYear = utils.moment(startDate).endOf("year")
    if (startDate.format("YYYY-MM-DD") === firstDayThisYear.format("YYYY-MM-DD") &&
        endDate.format("YYYY-MM-DD") === lastDayThisYear.format("YYYY-MM-DD"))
    {
        direction === 'next' ? newStartDate.add(1, 'year') : newStartDate.subtract(1, 'year')
        direction === 'next' ? newEndDate.add(1, 'year')   : newEndDate.subtract(1, 'year').endOf("year")
        willShow = true
    }

    // Month
    let firstDayThisMonth = utils.moment(startDate).startOf("month")
    let lastDayThisMonth = utils.moment(startDate).endOf("month")
    if (startDate.format("YYYY-MM-DD") === firstDayThisMonth.format("YYYY-MM-DD") &&
        endDate.format("YYYY-MM-DD") === lastDayThisMonth.format("YYYY-MM-DD"))
    {
        direction === 'next'
            ? newStartDate.add(1, 'month')
            : newStartDate.subtract(1, 'month')
        direction === 'next'
            ? newEndDate.add(1, 'month').endOf("month")
            : newEndDate.subtract(1, 'month').endOf("month")
        willShow = true
    }

    return willShow ? {startDate : newStartDate, endDate: newEndDate } : null
}

const Div = styled.div`
    ${props => props.className.split(' ').includes('highlighted') && props.type !== 'range' && css`
        border-left: 1px solid red;
        border-bottom: 1px solid red;
    `}
    ${props => props.className === 'label' && css`
        color: ${props.theme.label};
    `}
    ${props => props.className === 'date' && css`
        color: ${props.theme.subtitle};
    `}
    .react-datepicker {
        background: ${props => props.theme.overlay};
    }
    .react-datepicker__header {
        border-bottom: 1px solid ${props => props.theme.background};
        background: ${props => props.theme.overlay};
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header {
        color: ${props => props.theme.default};
    }
    .react-datepicker__day-name {
        color: ${props => props.theme.label};
    }
    .react-datepicker__day {
        color: ${props => props.theme.default};
        &:hover {
            background: ${props => props.theme.background};
        }
    }
    .react-datepicker__day--outside-month {
        color: ${props => props.theme.label};
    }

    .react-datepicker__day--today {
        box-shadow: 0 0 0 1px ${props => props.theme.background} inset;
    }
    .react-datepicker__day--keyboard-selected {

    }
    :not(.react-datepicker__day--selected) {

    }

    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end {
        background: ${props => props.theme.background};
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--selected {
        background: ${props => props.theme.primary} !important;
    }

    .react-datepicker__week-number {
        color: ${props => props.theme.label};
    }

    .DatePicker.focus &:hover:not(:focus) {
        background: ${props => props.theme.overlay};
    }
    .DatePicker-button {
      &:hover {
        background: ${props => props.theme.overlay};
      }
      &:focus {
        background: ${props => props.theme.overlay};
      }
    }

    ${props => props.className.split(' ').includes('messageBox') && css`
        input[type="text"] {
            background: ${props.theme.overlay};
            color: ${props.theme.default};
            text-align: center;
            padding: 2px 0;
        }
    `}
`;
