// MODULES
import { connect as _reduxConnect } from "react-redux"
import { bindActionCreators as _bindActionCreators } from "redux"
import _moment from "moment"
import LocationWrapper from "components/LocationWrapper/LocationWrapper"

export const moment = _moment
const locale = navigator.language
moment.updateLocale(locale, {
    week: {
        dow: new Intl.Locale(locale).weekInfo?.firstDay || 1 // day of week. 1: monday, 7: sunday
    }
})

export const createUniqueId = function(prefix, length = 10) {
    let result = (prefix || "") + "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) result += possible.charAt(Math.floor(Math.random() * possible.length));
    return result;
};

export const createUUID = function() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

export const isType = function(value, typeName) {
    return typeof value !== "undefined" && value.constructor.toString().indexOf(typeName) >= 0;
};

export const createClassName = function(initialClassName = "", conditionals = {}) {
    let conditionalClassName = "";
    Object.keys(conditionals).forEach(className => {
        if(!!conditionals[className]) conditionalClassName += " " + className;
    });

    return initialClassName + (initialClassName ? conditionalClassName : conditionalClassName.substring(1));
};

export const connectRedux = function(stateToProps, dispatchProps = {}, ComponentClass) {
    const mapStateToProps = (state) => ({ reducers: { ...stateToProps(state) } });
    const mapDispatchToProps = (dispatch) => {
        const result = { actions: {}, dispatch };
        Object.keys(dispatchProps).forEach(key => result.actions[key] = _bindActionCreators(dispatchProps[key], dispatch));
        return result;
    };

    const ConnectedComponent = _reduxConnect(mapStateToProps, mapDispatchToProps)(ComponentClass);

    return (props) => (
        <LocationWrapper>
            <ConnectedComponent {...props} />
        </LocationWrapper>
    )
};

export const parseQueryString = function(query) {
    const result = {};
    if(query[0] === "?") query = query.substring(1, query.length);

    query.split("&").forEach(param => {
    	param = param.split("=");
        result[param[0]] = (() => {
        	try {
        		return JSON.parse(param[1]);
            } catch(e) {
            	return param[1];
            }
        })();
    });

    return result;
};

export const createConstants = function(prefix, constants) {
    const result = {};
    constants.forEach(c => {
        const constant = `${prefix}_${c}`;
        result[c] = constant;
    });
    Object.freeze(result);
    return result;
};

export const closestInArray = function(num, arr, returnIndex = false) {
    let curr = arr[0];
    let diff = Math.abs(num - curr);

    for(let i = 0; i < arr.length; i++) {
        const newdiff = Math.abs(num - arr[i]);
        if(newdiff < diff) {
            diff = newdiff;
            curr = arr[i];
        }
    }

    return returnIndex === true ? arr.indexOf(curr) : curr;
};

export const getWindowDimensions = function() {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    };
};

export const requestAnimFrame = (function() {
    const _raf = window.requestAnimationFrame       ||
                 window.webkitRequestAnimationFrame ||
                 window.mozRequestAnimationFrame    ||
                 function(callback) { window.setTimeout(callback, 1000 / 60) };
  return _raf ? _raf.bind(window) : null;
})();

export const animate = function(props) {
    // const duration = 1000 * props.duration;
    const duration = props.duration;
    const end = +new Date() + duration;

    function step() {
        const current = +new Date();
        const remaining = end - current;

        if(remaining < 60) {
            props.run(1);  // 1 = progress is at 100%
            return;
        } else {
            const rate = 1 - remaining / duration;
            props.run(rate);
        }

        requestAnimFrame(step);
    }

    step();
};

export const getClosestElementByQuery = function(elem, selector, searchItself = true) {
    if(!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    if(searchItself === false && elem.parentNode) elem = elem.parentNode;

    for(; elem && elem !== document; elem = elem.parentNode) {
        if(elem.matches && elem.matches(selector)) return elem;
    }

    return null;
};

export const boundHorizontally = function(element, containerElement) {
    const elementRect = element.getBoundingClientRect();
    const containerElementRect = containerElement.getBoundingClientRect();

    return containerElementRect.left >= elementRect.right || containerElementRect.right <= elementRect.left;
}

export const boundVertically = function(element, containerElement) {
    const elementRect = element.getBoundingClientRect();
    const containerElementRect = containerElement.getBoundingClientRect();

    return containerElementRect.top >= elementRect.bottom || containerElementRect.bottom <= elementRect.top;
}


//format('{0} is dead, but {1} is alive!',['ASP','ASP.NET']);
export const format = function(text, args){
    let formatted = text;
    for(let arg in arguments){
      formatted = formatted.replace("{" + arg + "}", args[arg]);
   }
   return formatted;
}

export const toCamelCase = function(str) {
    return str.toLowerCase().replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9]+(.)/g, function(match, chr) {
        return chr.toUpperCase()
    })
}

export const isNumber = function(value) {
    return /^\d+$/.test(value.substr(value.length - 1));
}

export const isMobileDevice = function() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1 || /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(navigator.userAgent));
}; 

//does not account for holidays
export const getWorkdaysBetween = (startDate, endDate) => {
    let firstDate = moment(startDate, "YYYY-MM-DD").startOf("day")
    let lastDate = moment(endDate, "YYYY-MM-DD").startOf("day")
    let workdays = moment.duration(lastDate.diff(firstDate, 'days')) + 1

    for (let date = lastDate; date >= firstDate; date.subtract(1, 'day')) {
        // subtract weekends or if holiday is not on weekend
        if (moment(date).isoWeekday() === 6 || moment(date).isoWeekday() === 7
            || (moment(date).isoWeekday() !== 6 && moment(date).isoWeekday() !== 7
            && isHoliday(moment(date).format('YYYY-MM-DD'))))
                workdays--
    }

    return workdays
}

export const isHoliday = (date) => { // if date is on holiday list
    moment.easter = (year) => { // calculating current years easter
        var a = (year / 100 | 0) * 1483 - (year / 400 | 0) * 2225 + 2613;
        var b = ((year % 19 * 3510 + (a / 25 | 0) * 319) / 330 | 0) % 29;
        var c = 148 - b - ((year * 5 / 4 | 0) + a - b) % 7;
        return moment({ year: year, month: (c / 31 | 0) - 1, day: c % 31 + 1 });
    }

    return [
        moment(date).year() + '-01-01', // Nyårsdagen
        moment(date).year() + '-01-06', // Trettondedag jul
        moment.easter(moment(date).year()).subtract(2,'day').format('YYYY-MM-DD'), // Långfredagen
        moment.easter(moment(date).year()).add(1,'day').format('YYYY-MM-DD'), // Annandag påsk
        moment(date).year() + '-05-01', // Första maj
        moment(date).year() + '-06-06', // Nationaldagen
        moment.easter(moment(date).year()).add(39,'day').format('YYYY-MM-DD'), // Kristi himmelsfärdsdag
        moment(date).year() + '-12-25', // Juldagen
        moment(date).year() + '-12-26' // Annandag jul
    ].some(holiday => date === holiday)
}

function convertRgbaToHexa([r, g, b, a = 1]) {
    console.log(r, g, b, a)
    // Ensure alpha is between 0 and 1
    a = Math.round(a * 255);

    // Convert each RGB value to hexadecimal and ensure it's 2 digits
    let hexa = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

    // Convert alpha to hex and add it to the string
    let alphaHex = a.toString(16).padStart(2, '0');
    hexa += alphaHex;

    return hexa;
}

function convertHexaToRgba(hexa) {
    // Remove "#" if present
    hexa = hexa.replace(/^#/, '')

    // Expand shorthand hex (e.g., #RGBA → #RRGGBBAA)
    if (hexa.length === 4) {
        hexa = hexa.split('').map(x => x + x).join('')
    }

    // Extract RGBA values
    let r = parseInt(hexa.substring(0, 2), 16)
    let g = parseInt(hexa.substring(2, 4), 16)
    let b = parseInt(hexa.substring(4, 6), 16)
    let a = hexa.length === 8 ? parseInt(hexa.substring(6, 8), 16) / 255 : 1 // Default alpha = 1 if not provided
    a = Math.round(a * 100) / 100

    return [r, g, b, a]
}

// function stolen from https://github.com/Qix-/color-convert/blob/master/conversions.js row: 55
export const convertHexaToHsla = function(hexa) {
    const rgba = convertHexaToRgba(hexa)
    console.log("rgba", rgba)
    const r = rgba[0] / 255
    const g = rgba[1] / 255
    const b = rgba[2] / 255
    const a = rgba[3] || 1

    const min = Math.min(r, g, b)
    const max = Math.max(r, g, b)
    const delta = max - min
    let h
    let s

    if (max === min) {
        h = 0;
    } else if (r === max) {
        h = (g - b) / delta
    } else if (g === max) {
        h = 2 + (b - r) / delta
    } else if (b === max) {
        h = 4 + (r - g) / delta
    }

    h = Math.min(h * 60, 360)

    if (h < 0) {
        h += 360
    }

    const l = (min + max) / 2

    if (max === min) {
        s = 0;
    } else if (l <= 0.5) {
        s = delta / (max + min)
    } else {
        s = delta / (2 - max - min)
    }

    return [h, s * 100, l * 100, a]
}

//function stolen from https://github.com/Qix-/color-convert/blob/master/conversions.js row: 244
export const convertHslaToHexa = function(hsla) {
	const h = hsla[0] / 360
	const s = hsla[1] / 100
	const l = hsla[2] / 100
	const a = hsla[3]

	let t2
	let t3
	let val

	if (s === 0) {
		val = l * 255
        return convertRgbaToHexa([val, val, val, a])
	}

	if (l < 0.5) {
		t2 = l * (1 + s)
	} else {
		t2 = l + s - l * s
	}

	const t1 = 2 * l - t2

	const rgba = [0, 0, 0, a];
	for (let i = 0; i < 3; i++) {
		t3 = h + 1 / 3 * -(i - 1)
		if (t3 < 0) {
			t3++
		}

		if (t3 > 1) {
			t3--
		}

		if (6 * t3 < 1) {
			val = t1 + (t2 - t1) * 6 * t3
		} else if (2 * t3 < 1) {
			val = t2
		} else if (3 * t3 < 2) {
			val = t1 + (t2 - t1) * (2 / 3 - t3) * 6
		} else {
			val = t1
		}

		rgba[i] = Math.round(val * 255)
	}

    return convertRgbaToHexa(rgba)
}

export const getNivoIcon = function (icon) {
    if (icon.toString().charAt(0) !== "/") icon = `/${icon}`
    if (!icon.toString().includes(".")) icon = `/${icon}.png`
return `https://raw.githubusercontent.com/plouc/nivo/master/website/src/assets/icons${icon}`
}

export const filterDuplicatesByKey = (array = [], key) => {
    return array.filter((item, index, self) => (
        index === self.findIndex((t) => (
            t[key] === item[key]
        ))
    ))
}

export const calculateHourlyCost = (salary, payroll, pension) => salary + (salary * (payroll / 100)) + (salary * (pension / 100))

export const calculateMonthlyCost = (salary, payroll, pension, salaryForPeriodPercantage = 1) => {
    if (parseFloat(payroll) === 19.73 && salary > 25000) { // 19.73 is acting as a unique flag
        let remainder  = salary - 25000
        let firstPart  = 25000 + (25000 * (19.73 / 100)) + (25000 * (pension / 100))
        let secondPart = remainder + (remainder * (31.42 / 100)) + (remainder * (pension / 100))

        return (firstPart + secondPart) * salaryForPeriodPercantage
    } else {
        return (salary + (salary * (payroll / 100)) + (salary * (pension / 100))) * salaryForPeriodPercantage
    }
}

export const getTimelineStatistics = (timelines, period, type) => {
    let totalHours = 0
    let totalFee = 0
    let totalCost = 0
    let totalSalary = 0
    let extraFee = 0
    let extraCost = 0

    timelines.forEach(timeline => {
        if (type === "shadows" && !timeline.context.shadow) return

        let periodStartDate = moment(period.startDate)
        let periodEndDate = moment(period.endDate)
        let totalExtra = getExtraStatistics(timeline.context.extras, periodStartDate, periodEndDate)
        extraFee += totalExtra.fee
        extraCost += totalExtra.cost

        let totalRows = getRowStatistics(timeline.rows)
        totalHours += totalRows.hours
        totalFee += totalRows.fee
        totalCost += totalRows.cost
        totalSalary += totalRows.salary
    })

    return {
        totalHours: (Math.round(totalHours * 2) / 2),
        totalFee: (Math.round(totalFee * 2) / 2),
        totalCost: (Math.round(totalCost * 2) / 2),
        totalSalary: (Math.round(totalSalary * 2) / 2),
        extraFee: (Math.round(extraFee * 2) / 2),
        extraCost: (Math.round(extraCost * 2) / 2)
    }
}

export const getRowStatistics = (rows) => {
    let hours = 0
    let fee = 0
    let cost = 0
    let salary = 0

    rows.forEach(row => {
        let totalCells = getCellStatistics(row.cells)
        hours += totalCells.hours
        fee += totalCells.fee
        cost += totalCells.cost
        salary += totalCells.salary
    })

    return {
        hours, fee, cost, salary
    }
}

const getCellStatistics = (cells) => {
    let hours = 0
    let fee = 0
    let cost = 0
    let salary = 0

    cells.forEach(cell => {
        if (cell.hour) hours += parseFloat(cell.hour)
        if (cell.fee) fee += parseFloat(cell.fee * cell.hour)
        if (cell.hourlySalary) salary += (parseFloat(cell.hourlySalary * cell.hour) * cell.salaryMultiplier / 100)

        const totalSalaryCost = cell.hourlySalary * cell.hour * cell.salaryMultiplier / 100
        if (cell.hourlySalary) cost += calculateHourlyCost(totalSalaryCost, cell.payroll, cell.pension)
    })

    return {
        hours, fee, cost, salary
    }
}

export const getExtraStatistics = (extras, periodStartDate, periodEndDate) => {
    let fee = 0
    let cost = 0

    if (!!extras) {
        extras.forEach(extra => {
            let extraStartDate = moment(extra.StartDate)
            let extraEndDate   = moment(extra.EndDate)
            
            if (extraStartDate <= periodEndDate && extraEndDate >= periodStartDate) {
                let extraEndDateInPeriod   = extraEndDate > periodEndDate ? periodEndDate : extraEndDate
                let extraStartDateInPeriod = extraStartDate < periodStartDate ? periodStartDate : extraStartDate
                let daysOfExtraInPeriod = extraEndDateInPeriod.diff(extraStartDateInPeriod, 'days') + 1
                let daysOfExtraTotal = extraEndDate.diff(extraStartDate, 'days') + 1
                let extraPercentageInPeriod = daysOfExtraInPeriod / daysOfExtraTotal
                if (extra.Fee) fee += parseFloat(extra.Fee * extraPercentageInPeriod)
                if (extra.Cost) cost += parseFloat(extra.Cost * extraPercentageInPeriod)
            }
        })
    }

    return {
        fee, cost
    }
}

export const getTotalOverhead = (overheads, period, timeReporter) => {
    let totalOverheadCost = 0 // initial overhead
    let totalOverheadFee = 0 // initial overhead

    if (overheads.length > 0) { // if there is at least one declared overhead
        overheads.map(overhead => {
            if (!overhead.EndDate) overhead.EndDate = timeReporter.getCurrentPeriod().endDate // set endDate today to end of period if it's not declared

            let currentDate = moment(overhead.StartDate), endDate = moment(overhead.EndDate),
            periodStartDate = moment(period.startDate), periodEndDate = moment(period.endDate)

            while (currentDate.diff(endDate) <= 0) { // loop through each day of overhead period
                if (currentDate.diff(periodStartDate) >= 0 && currentDate.diff(periodEndDate) <= 0) { // if date is in range of our period
                    totalOverheadCost += overhead.Cost / currentDate.daysInMonth()
                    totalOverheadFee += overhead.Fee / currentDate.daysInMonth()
                } // add daily overhead of current month

                currentDate = currentDate.add(1, 'day')
            }

            return true
        }) // go to next day
    }

    return {
        overheadFee: Math.round(totalOverheadFee * 2) / 2,
        overheadCost: Math.round(totalOverheadCost * 2) / 2
    }
}