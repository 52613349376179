import React from "react";

// CLASSES
import Settings from "classes/Settings";
import styled, {css} from 'styled-components';

// COMPONENTS
import Vector from "components/Vector/Vector";
import Statistics from "components/Statistics/Statistics";

// HELPERS
import * as api from "helpers/api";
import * as utils from "helpers/utils";
import i18n from "helpers/i18n";


export default class Timeline extends React.PureComponent {
    state = {
        expanded: false,
        ..._refreshData.call(this),
    }

    componentDidUpdate(prevProps) {
      const { props } = this;
      let newStateProps = {};

      if(prevProps.data !== props.data) newStateProps = { ..._refreshData.call(this) };

      if(Object.keys(newStateProps).length > 0) this.setState(newStateProps);
    }

    render() {
        const { index: timelineIndex, period, data, mode, onCellClick, onHover, view } = this.props
        const { expanded, summaryCells, totalHours, totalFee, totalCost, totalSalary, extraFee, extraCost } = this.state
        const { rows, context } = data
        const { spread } = context

        const classes = utils.createClassName("Timeline", {
            "expand": expanded,
        })
        const showShadows = Settings.get("OVERVIEW_INCLUDE_SHADOWS_" + view)

        return (
            <StyledTimeline className={classes}>
                <TimelineToolbar
                    data={data}
                    mode={mode}
                    expanded={expanded}
                    totalHours={totalHours}
                    totalFee={totalFee}
                    extraCost={extraCost}
                    totalSalary={totalSalary}
                    extraFee={extraFee}
                    totalCost={totalCost}
                    showExpandToggle={Settings.get("OVERVIEW_INCLUDE_SHADOWS_" + view) ? rows.length > 1 : rows.filter(e => (!e.shadow || (!!e.shadow && !e.isEmpty))).length > 1}
                    onExpandToggle={this._onExpandToggle}
                    onContextReviewClick={this._onContextReviewClick}
                />

                <div className="Timeline-scrollable scrollable-x">
                    <div className="container">
                        <TimelineDates period={period} />
                        <TimelineSummary
                            rows={rows.filter(e => ((e.shadow && e.isEmpty) && !showShadows ? false : true))}
                            cells={summaryCells}
                            spread={spread}
                            mode={mode}
                            view={view}
                            timelineIndex={timelineIndex}
                            onCellClick={onCellClick}
                            onHover={onHover}
                            period={period}
                        />
                        <div className="Timeline-rows">
                            {rows.map((row, index) => (
                                <TimelineRow
                                    key={`Timeline-row-${index}`}
                                    id={row.id}
                                    cells={row.cells}
                                    spread={spread}
                                    mode={mode}
                                    view={view}
                                    context={{
                                        avatarPath: row.avatarPath,
                                        color: row.color,
                                        textColor: row.textColor,
                                        cellImagePath: row.cellImagePath,
                                        title: row.title
                                    }}
                                    rowsCount={rows.filter(r => r.assigning.SM === 100).length}
                                    assigning={row.assigning}
                                    isShadow={row.shadow}
                                    isEmpty={row.isEmpty}
                                    rowsRef={rows}
                                    timelineIndex={timelineIndex}
                                    onHover={onHover}
                                    onCellClick={onCellClick}
                                    period={period}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </StyledTimeline>
        )
    }

    // Internal methods
    _onExpandToggle = () => this.setState(prevState => ({ expanded: !prevState.expanded }))

    _onContextReviewClick = () => {
        const { data, onContextReviewClick } = this.props;
        if(onContextReviewClick) onContextReviewClick(data.id, data.context);
    }
};

// PRIVATE COMPONENTS
class TimelineToolbar extends React.PureComponent {

    render() {
        const { props } = this;
        const { data, mode, totalHours, totalFee, totalCost, totalSalary, showExpandToggle, onExpandToggle, onContextReviewClick, expanded, extraFee, extraCost } = props;
        const classes = utils.createClassName('Timeline-toolbar', {
            'expandable': showExpandToggle
        })

        return (
            <StyledTimelineToolbar className={classes}>
                <div className="context-info">
                    <div className="avatar" style={{ backgroundImage: `url(${api.getAssetsURIForPath(data.context.avatarPath)})` }} onClick={ onContextReviewClick } />
                    <TimelineName className="name" onClick={ onContextReviewClick }>{ data.context.name }</TimelineName>
                    <Statistics 
                        type="timeline"
                        mode={mode}
                        data={{
                            totalHours,
                            totalFee,
                            totalCost,
                            totalSalary,
                            extraFee,
                            extraCost,
                            totalReportedHours: data.context.totalReportedHours
                        }}
                    />
                </div>
                <div className='icon-container'>
                    { data.context.shadow === 1 && (<ShadowSign className="shadow-sign" title={ i18n("general", "shadow") }>
                        <i className='far fa-eye-slash'></i>
                    </ShadowSign>)}
                    { showExpandToggle  && (<ExpandButton $expanded={expanded} className="expand-button" onClick={ onExpandToggle } title={ i18n("general", "expand") }>
                        <span data-icon="e" />
                    </ExpandButton>)}
                </div>

            </StyledTimelineToolbar>
        );
    }
}

function TimelineDates(props) {
    const { period } = props;
    const startDate = utils.moment(period.startDate, "YYYY-MM-DD");
    const endDate = utils.moment(period.endDate, "YYYY-MM-DD");
    const amountOfDays = endDate.diff(startDate, "days") + 1;
    const dates = (() => {
        const result = [];
        for(let i = 0; i < amountOfDays; i++) {
            result.push(result.length === 0 ? startDate : utils.moment(result[i-1]).add(1, "days"));
        }
        return result;
    })();

    return (
        <div className="Timeline-dates">
        { dates.map((date, index) => (
            <TimelineDate
                key={ `Timeline-date-${index}` }
                date={ date }
                holiday={ period.holidays.indexOf(date.format("YYYY-MM-DD")) >= 0 }
            />
        ))}
        </div>
    );
}

function TimelineDate(props) {
    const { date, holiday } = props;
    const dateToday = utils.moment();
    const isToday = dateToday.format("YYYY-MM-DD") === utils.moment(date).format("YYYY-MM-DD");
    const classes = utils.createClassName("Timeline-date", {
        "today": isToday,
        "holiday": holiday,
    });

    return (
        <StyledTimelineDate $classes={classes.split(' ')} className={ classes } title={ date.format("YYYY-MM-DD") + (isToday ? " (today)" : "") } style={{ fill: Settings.getGlobalColor(!holiday ? 'subtitle' : 'danger') }} >
            <Vector width={ 100 } height={ 17 } className="date" >
                <text x="50%" y="15" baseline="middle" textAnchor="middle">
                    { date.format("DD/MM") }
                </text>
            </Vector>
        </StyledTimelineDate>
    );
}



function TimelineSummary(props) {
    const { onClick, rows, timelineIndex, onCellClick, onHover, mode, spread, view, period } = props

    const imagesQueryString = "/combine-images?images=" + (() => {
        let images = "";
        let colors = [];
        rows.forEach((rd, i) => {
            if (rd.cellImagePath === '') images += 'C=' + rd.color.substring(1,rd.color.length) + (i < rows.length-1 ? "," : "");
            else images += rd.cellImagePath + (i < rows.length-1 ? "," : "");
            colors.push(rd.color.substring(1,rd.color.length));
        });
        images += '&colors=';
        for(var i = 0; i < colors.length; i++){
          images += colors[i] + (i < colors.length-1 ? "," : "");
        }

        return images;
    })();

    const cells = props.cells.map(cell => {
        return {
            ...cell,
            cellImagePath: process.env.REACT_APP_API_URL + imagesQueryString + "&percentages=" + cell.percentages.join(",")
        };
    });

    return (
        <StyledTimelineSummary className="Timeline-summary" onClick={ onClick }>
            <TimelineRow
                cells={cells}
                rowsRef={rows}
                mode={mode}
                view={view}
                spread={spread}
                timelineIndex={timelineIndex}
                onCellClick={onCellClick}
                onHover={onHover}
                period={period}
                isSummary
            />
        </StyledTimelineSummary>
    )
}

class TimelineRow extends React.PureComponent {
    state = this._getInitialState()

    componentDidUpdate(prevProps) {
        const { props } = this;
        const newStateProps = {};

        // Cell(s) have been updated
        if(prevProps.cells !== props.cells) {
            newStateProps.cells = this._mapCellsFromProps();
        }

        if(Object.keys(newStateProps).length > 0) this.setState(newStateProps);
    }

    render() {
        const { context, onCellClick, onHover, rowsRef, timelineIndex, isSummary, mode, spread, isShadow, isEmpty, view } = this.props
        const { cells } = this.state

        const CellComponent = isSummary ? TimelineCellSummary : TimelineCell

        const classes = utils.createClassName("Timeline-row", {
            "Timeline-summary-row": !!isSummary,
            "Timeline-shadow-row": !!isShadow && !Settings.get("OVERVIEW_INCLUDE_SHADOWS_" + view),
            "empty-row": !!isEmpty && !!isShadow && !Settings.get("OVERVIEW_INCLUDE_SHADOWS_" + view)
        })

        const rowsInfo = (
            isSummary ? rowsRef.map(r => (
                  {
                    avatarPath: r.avatarPath,
                    title: r.title ,
                    value: r.hours,
                    hours: r.hours,
                    unit: mode === "hours" ? "h" : Settings.get("CURRENCY"),
                    startDate: r.assigning.startDate,
                    endDate: r.assigning.endDate,
                  })) :
            [{ avatarPath: context.avatarPath,
               title: context.title,
               value: 0,
               totalValue: context,
               unit: mode === "hours" ? "h" : Settings.get("CURRENCY"),
               startDate: rowsRef[0].assigning.startDate,
               endDate: rowsRef[0].assigning.endDate,
             }]
        )

        return (
            <div className={ classes }>
                {cells.map((cell, index, array) => {
                    if (!isSummary) {
                        cell.total = {
                            hours: array.reduce((v, cell) => v + (cell.hour || 0), 0),
                            fee: array.reduce((v, cell) => v + ((cell.hour * cell.fee) || 0), 0),
                            cost: array.reduce((v, cell) => v + (utils.calculateHourlyCost(cell.hour * cell.hourlySalary * (cell.salaryMultiplier / 100), cell.payroll, cell.pension) || 0), 0),
                            salary: array.reduce((v, cell) => v + ((cell.hour * cell.hourlySalary * (cell.salaryMultiplier / 100)) || 0), 0),
                        }
                    }

                    const uid = `Timeline-${timelineIndex}-cell-${index}`;

                    const cellClickData = {
                        date: cell.date,
                        isSingle: !isSummary,
                        dataset: isSummary ? rowsRef.map(r => ({
                            ...r.cells[index],
                            cellImagePath: r.cellImagePath,
                            avatarPath: r.avatarPath,
                            title: r.title,
                            placeholder: !!r.cells[index]?.placeholder,
                            spread: spread,
                            shadow: r.shadow
                        })) : [{
                            ...cell,
                            color: context.color,
                            textColor: context.textColor,
                            cellImagePath: context.cellImagePath,
                            avatarPath: context.avatarPath,
                            title: context.title,
                            spread: spread,
                            shadow: isShadow
                        }]
                    }

                    return (
                        <CellComponent
                            key={ uid }
                            uid={ uid }
                            cell={ cell }
                            mode={mode}
                            rowsInfo={ rowsInfo }
                            onHover = { onHover }
                            spread = { spread }
                            onClick={ onCellClick ? () => onCellClick(cellClickData) : null }
                        />
                    )
                })}
            </div>
        )
    }

    // Internal methods
    _getInitialState() {
        return { cells: this._mapCellsFromProps() }
    }

    _mapCellsFromProps() {
        const { cells, context, isSummary } = this.props

        return (
            !isSummary ? cells.map(cell => ({
                ...cell,
                color: context.color,
                textColor: context.textColor,
                cellImagePath: context.cellImagePath,
            })) : cells
        )
    }
}

class TimelineCell extends React.PureComponent {

    render() {
        const { cell, onClick } = this.props // rowsInfo, stop, mode
        const { date, color, textColor, cellImagePath, hour: value } = cell;

        const classes = utils.createClassName("Timeline-cell", {
            "today": utils.moment().format("YYYY-MM-DD") === utils.moment(date).format("YYYY-MM-DD"),
            //"empty": value === null,
            "placeholder": cell.placeholder,
            'empty-cell': cell.id === null
        })

        if (cell.placeholder) {
            return <div className={classes}>
                <Vector width={100} height={100} className="value" style={{ fill: Settings.getGlobalColor('subtitle') }} >
                    <rect x="0" y="0" height="100" width="100" fill="transparent" fillOpacity={color !== "" ? 1 : 0}/>
                    <image xlinkHref="" x="0" y="0" height="100" width="100" />
                    <text x="50%" y={66} fontSize="50" baseline="middle" textAnchor="middle" fill=""></text>
                </Vector>
            </div>
        }

        return (
            <div className={classes} onClick={onClick} onMouseOver={this.onHover} onMouseOut={this.onMouseLeave}
                onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} onTouchMove={this.onTouchMove}
            >
                <Vector width={100} height={100} className="value" style={{ fill: Settings.getGlobalColor('primary') }}>
                    <rect x="0" y="0" height="100" width="100" fill={color} fillOpacity={color !== "" ? 1 : 0}/>
                    <image xlinkHref={cellImagePath !== "" ? api.getAssetsURIForPath(cellImagePath) : ""} x="0" y="0" height="100" width="100" />
                    <text x="50%" y={66} fontSize="50" baseline="middle" textAnchor="middle" fill={textColor}>{value != null ? value : ""}</text>
                </Vector>
            </div>
        )
    }

    onMouseLeave = () => this.props.onHover && this.props.onHover(null)

    onHover = (e, mobile = false) => {
        const { onHover, cell, rowsInfo, mode } = this.props
        const { x, y, width, height } = e.target.parentElement.getBoundingClientRect()

        if (onHover) {
            const data = {
                date: cell.date,
                data: rowsInfo,
                cell: cell,
                mode: mode,
                x,
                y: y + 1,
                width,
                height,
                mobile
            }

            onHover(data)
        }
    }

    onTouchStart = (e) => this.onHover(e, true)
    onTouchEnd = () => this.props.onHover(null)
    onTouchMove = () => this.props.onHover(null)
}

class TimelineCellSummary extends React.PureComponent {
    render() {
        const { cell, onClick, uid,
            // rowsInfo, onHover, mode
        } = this.props
        const { date, value, textColors, cellImagePath, percentages } = cell
        const classes = utils.createClassName("Timeline-cell Timeline-summary-cell", {
            "today": utils.moment().format("YYYY-MM-DD") === utils.moment(date).format("YYYY-MM-DD"),
            "placeholder": cell.placeholder
        })

        const textColorStops = []

        if (percentages.length > 0) {
            let percSum = [...percentages].reduce((a,b) => a + b, 0)
            let perc = 0
            const newTextColors = textColors.filter((c, i) => !!percentages[i])

            percentages.filter(p => !!p).forEach((p, i, a) => {
                if (i === 0) { // if first
                    textColorStops.push(<stop key={`textColorStop-${uid}-${i}-0`} offset="0%" stopColor={newTextColors[i]} />)
                } else {
                    textColorStops.push(<stop key={`textColorStop-${uid}-${i}-1`} offset={ perc / percSum * 100 + "%" } stopColor={newTextColors[i]} />)
                }

                perc += p

                if (i === a.length - 1) { // if last
                    textColorStops.push(<stop key={`textColorStop-${uid}-${i}-2`} offset="100%" stopColor={newTextColors[i]} />)
                } else {
                    textColorStops.push(<stop key={`textColorStop-${uid}-${i}-3`} offset={perc / percSum * 100 + "%"} stopColor={newTextColors[i]} />)
                }
            })
        }

        let hasAnyValues = cell.values.some(el => el !== null)

        if (cell.placeholder) {
            return (
                <div className={classes}>
                    {(value || true)}

                    <Vector key={utils.createUniqueId()} width={100} height={100} className="value">
                        <defs>
                            <linearGradient id={uid + "-textColor"} x1="0%" y1="25%" x2="0%" y2="75%">{textColorStops}</linearGradient>
                            <mask id={uid + "-mask"} x="0" y="0" width="100%" height="100%">
                                <rect x="0" y="0" width="100%" height="100%" fill="#000000" />
                                <text x="50%" y="66" fontSize="50" baseline="middle" textAnchor="middle" fill="#ffffff"></text>
                            </mask>
                        </defs>
                        <image xlinkHref="" x="0" y="0" height="100" width="100" />
                        <rect x="0" y="0" width="100%" height="100%" mask={`url(#${uid + '-mask'})`} fill={`url(#${uid + '-textColor'})`} />
                    </Vector>
                </div>
            )
        }

        return (
            <div className={classes} uid={uid} onClick={onClick} onMouseOver={this.onHover} onMouseOut={this.onMouseLeave}
            onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} onTouchMove={this.onTouchMove}>
                <Vector key={utils.createUniqueId()} width={100} height={100} className="value">
                    <defs>
                        <linearGradient id={uid + "-textColor"} x1="0%" y1="28%" x2="0%" y2="66%">{textColorStops}</linearGradient>
                        <mask id={uid + "-mask"} x="0" y="0" width="100%" height="100%">
                            <rect x="0" y="0" width="100%" height="100%" fill="#000000" />
                            <text x="50%" y="66" fontSize="50" baseline="middle" textAnchor="middle" fill="#ffffff">{hasAnyValues ? value : ""}</text>
                        </mask>
                    </defs>
                    <image xlinkHref={cellImagePath} x="0" y="0" height="100" width="100" />
                    <rect x="0" y="0" width="100%" height="100%" mask={`url(#${uid + '-mask'})`} fill={`url(#${uid + '-textColor'})`} />
                </Vector>
            </div>
        )
    }

    onMouseLeave = () => this.props.onHover && this.props.onHover(null)

    onHover = (e, mobile = false) => {
        const { onHover, cell, rowsInfo, mode } = this.props
        const { x, y, width, height } = e.target.parentElement.getBoundingClientRect()

        if (onHover) {
            const data = {
                date: cell.date,
                data: rowsInfo,
                cell: cell,
                mode: mode,
                x,
                y: y + 1,
                width,
                height,
                mobile
            }

            onHover(data)
        }
    }

    onTouchStart = (e) => this.onHover(e, true)
    onTouchEnd = () => this.props.onHover(null)
    onTouchMove = () => this.props.onHover(null)
}

// PRIVATE FUNCTIONS
function _getDateArray(startDate, endDate) {
    let dates = [];
    let currentDate = utils.moment(startDate);

    while (currentDate <= utils.moment(endDate)) {
        dates.push(utils.moment(currentDate).format("YYYY-MM-DD"))
        currentDate.add(1, "days")
    }

    return dates
}

function findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

function _refreshData() {
    const { data, period, view } = this.props
    const { rows, context } = data
    const { holidays } = period

    const returnValue = {}

    const dates = _getDateArray(period.startDate, period.endDate)
    const showShadows = Settings.get("OVERVIEW_INCLUDE_SHADOWS_" + view)

    let emptySummaryCells = (() => {
        const result = [];
        dates.forEach((date, i) => {
            result.push({
                date: date,
                colors: [],
                textColors: [],
                cellImagePath: null,
                values: [],
                fees: [],
                costs: [],
                salaries: [],
                percentages: [],
                placeholders: [],
                placeholder: true,
                missingTimereports: []
            });

            // let totalHours = 0;
            // let totalFee = 0;
            // let totalCost = 0;

            // let placeholder = true;
            result[i].value = holidays.indexOf(date) >= 0 ? 0 : null;
            result[i].fee = 0;
            result[i].cost = 0;
            result[i].salary = 0;
            result[i].placeholder = true;

        });
        return result;
    })();

    returnValue.summaryCells = (() => {
        const result = emptySummaryCells;
        rows
            .filter(e => ((e.shadow && e.isEmpty) && !showShadows ? false : true))
            .forEach((row) => {

            row.cells.forEach((currentcell) => {
                let currentDate = currentcell.date;

                let index = findWithAttr(emptySummaryCells, 'date', currentDate);
                let summaryCell = emptySummaryCells[index];

                if (summaryCell !== undefined) {
                    summaryCell.shadows = summaryCell.shadows ? summaryCell.shadows : []
                    summaryCell.shadows.push(row.shadow)

                    summaryCell.placeholder = (!showShadows && currentcell.hour == null && row.shadow) || currentcell.placeholder;
                    summaryCell.placeholders.push(summaryCell.placeholder ? true : false);

                    summaryCell.placeholders.forEach((placeholder) => {
                        if (!placeholder){
                          summaryCell.placeholder = false;
                          return;
                        }
                    });

                    summaryCell.textColors.push(row.textColor);
                    summaryCell.colors.push(row.color);
                    if (currentcell.hour !== null) {
                        summaryCell.value += currentcell.hour;
                        let totalSalary = currentcell.hourlySalary * currentcell.hour; // no salary multipler here, because it will be added later in resource/project calculation

                        let fee = (parseFloat(currentcell.fee !== null ? currentcell.fee : 0) * currentcell.hour);
                        summaryCell.fee = parseFloat(summaryCell.fee !== null ? summaryCell.fee : 0) + fee;
                        summaryCell.fees.push(fee)
                        
                        let cost = utils.calculateHourlyCost(totalSalary, currentcell.payroll, currentcell.pension) * (currentcell.salaryMultiplier / 100)
                        summaryCell.cost += cost
                        summaryCell.costs.push(cost)

                        let salary = totalSalary * (currentcell.salaryMultiplier / 100)
                        summaryCell.salary += salary;
                        summaryCell.salaries.push(salary)

                        summaryCell.values.push(currentcell.hour !== null ? currentcell.hour : 0);

                    } else {
                        summaryCell.salaries.push(null)
                        summaryCell.costs.push(null)
                        summaryCell.fees.push(null)
                        summaryCell.values.push(null)
                    }

                    summaryCell.missingTimereports.push( (!summaryCell.placeholder && currentcell.hour == null) ? true : false  );

                  }
              });
        });

        return result;
    })();

    returnValue.summaryCells.forEach((summaryCell) => {
        let nPercents = 0
        let totalHours = summaryCell.value

        summaryCell.values.forEach((value, i) => {
            if (
                (value !== null && summaryCell.placeholders[i] === false && summaryCell.shadows[i] !== 1) ||
                (value === 0    && summaryCell.placeholders[i] === true ) ||
                (value === null && summaryCell.placeholders[i] === false && summaryCell.shadows[i] !== 1)
            ) {
                nPercents++
            }
        })

        summaryCell.values.forEach((currentHour, i) => {
            let allNull = summaryCell.values.every((el) => {
                return (el === null)
            })

            let allZero = summaryCell.values.every((el) => {
                return (el === 0)
            })

            let hasEmptyShadow = false
            let numberOfEmptyShadows = 0
            summaryCell.values.forEach((e, i) => {
                if (!!summaryCell.shadows[i] && (e === null || e === 0)) {
                    hasEmptyShadow = true
                    numberOfEmptyShadows++
                }
            })

            let hasAnyNull = false
            summaryCell.values.forEach((currentHour, i) => {
                if (currentHour === null && !summaryCell.shadows[i]) {
                    hasAnyNull = true
                }
            })

            let hasAnyPlaceholders = false
            summaryCell.placeholders.forEach((current) => {
                if(current === true) {
                    hasAnyPlaceholders = true
                }
            })

            const allPlaceholders = summaryCell.placeholders ? summaryCell.placeholders.every(function (placeholder, i) {
                if (summaryCell.shadows[i]) return true
                return placeholder
            }) : false

            const onlyShadow = allPlaceholders ? summaryCell.placeholders.every(function (placeholder, i) {
                if (summaryCell.shadows[i]) return allPlaceholders
                return placeholder
            }) : false

            if (!!summaryCell.shadows[i] && (currentHour === null || currentHour === 0) && hasAnyNull && !summaryCell.placeholders[i] && onlyShadow) {
                summaryCell.percentages.push(100)
            } else if (!!summaryCell.shadows[i] && (currentHour === null || currentHour === 0) && onlyShadow) {
                const isCellEmpty = numberOfEmptyShadows === summaryCell.shadows.length
                const percent = isCellEmpty
                    ? Math.round(100 / (summaryCell.shadows.length))
                    : 0
                summaryCell.percentages.push(percent)
            } else if (!!summaryCell.shadows[i] && (currentHour === null || currentHour === 0)) {
                summaryCell.percentages.push(0)
            } else if (hasAnyNull && !hasAnyPlaceholders) {
                if (hasEmptyShadow) {
                    summaryCell.percentages.push(Math.round(100 / (summaryCell.values.length - numberOfEmptyShadows)));
                } else {
                    summaryCell.percentages.push(Math.round(100 / (summaryCell.values.length)));
                }
            } else if (((allNull && summaryCell.placeholders[i] === false) || (allZero && summaryCell.placeholders[i] === false)) && hasAnyPlaceholders) {
                summaryCell.percentages.push(Math.round(100 / (nPercents)));
            } else if( (allNull && summaryCell.placeholders[i] === false) || (allZero && summaryCell.placeholders[i] === false) ) {
                if (hasEmptyShadow) {
                    summaryCell.percentages.push(Math.round(100 / (summaryCell.values.length - numberOfEmptyShadows)));
                } else {
                    summaryCell.percentages.push(Math.round(100 / (summaryCell.values.length)));
                }
            } else {
                if (summaryCell.placeholders[i] === true) {
                    summaryCell.percentages.push(0);
                } else {
                    let newPercent = Math.round(currentHour / (totalHours) * 100);
                    if (hasAnyNull || isNaN(newPercent)) {
                        newPercent = Math.round(100 / (nPercents))
                    }
                    summaryCell.percentages.push(newPercent)
                }
            }
        })
    })

    const periodStartDate = utils.moment(period.startDate)
    const periodEndDate = utils.moment(period.endDate)
    const total = utils.getRowStatistics(rows)
    const extra = utils.getExtraStatistics(context.extras, periodStartDate, periodEndDate)
    
    // round values
    returnValue.totalHours = (Math.round(total.hours * 2) / 2) || 0
    returnValue.totalFee = (Math.round(total.fee * 2) / 2) || 0
    returnValue.totalCost = (Math.round(total.cost * 2) / 2) || 0
    returnValue.extraFee = (Math.round(extra.fee * 2) / 2) || 0
    returnValue.extraCost = (Math.round(extra.cost * 2) / 2) || 0
    returnValue.totalSalary = (Math.round(total.salary * 2) / 2) || 0

    return returnValue
}


const StyledTimelineSummary = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.background};
`;

const StyledTimelineToolbar = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.background};
`;

const TimelineName = styled.div`
    color: ${(props) => props.theme.primary};
`;

const ShadowSign = styled.div`
    color: ${(props) => props.theme.label};
`;

const ExpandButton = styled.div`
    &:hover {
        background: ${(props) => props.theme.background};
    }

    ${props => props.$expanded && css`
        color: ${props.theme.secondary};
        border: 1px solid ${props.theme.secondary};
    `}
`;

const StyledTimelineDate = styled.div`
    ${props => props.$classes.includes('today') && css`
        &::after {
            background: ${props.theme.secondary};
        }
    `}
`;

const StyledTimeline = styled.div`
    background: ${(props) => props.theme.overlay};
`;
