import React from "react"
import { Route, Link, Routes } from "react-router-dom"
import styled, { css, createGlobalStyle, ThemeProvider } from "styled-components"

import packgeJson from "../package.json"

// CLASSES
import Settings from "classes/Settings"
import GlobalColors from "classes/GlobalColors"

import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator"

// ROUTES
import ResetPassword from "routes/ResetPassword/Resetpassword"
import PublicLink from "routes/PublicLink/PublicLink"
import Login from "routes/Login/Login"
import Overview from "routes/TimeReport/subroutes/Overview/Overview"
import PunchClockLogin from "routes/PunchClockLogin/PunchClockLogin"
import PunchClockBoard from "routes/TimeReport/subroutes/Overview/PunchClockBoard"

// HELPERS
import {
    connectRedux,
    createClassName,
    parseQueryString
} from "helpers/utils"
import i18n from "helpers/i18n"
import api, { getAssetsURIForPath } from "helpers/api"

// REDUCERS
import { actions as appActions } from "reducers/app"

class App extends React.Component {
    async componentDidMount() {
        await GlobalColors.initGlobalColors(reload => {
            //If colors have been updated then we need to reload the page
            if (reload) window.location.reload()
        })

        const globalColors = GlobalColors.globalColors
        Object.keys(globalColors).forEach((key) => {
            const color = globalColors[key]
            document.documentElement.style.setProperty(`--${key}`, color);
        })

        const { actions, navigate, location } = this.props
        const authToken = Settings.get("AUTH_TOKEN")
        const punchClockAuthToken = Settings.get("PUNCHCLOCK_AUTH_TOKEN")
        _setupOptions.call(this)

        this.updateHeight()
        window.addEventListener('resize', this.updateHeight)
        window.addEventListener('orientationchange', this.updateHeight)

        if (location.pathname === "/password-reset") {
            navigate("/password-reset" + location.search)
        } else if (location.pathname.includes("publicdashboard")) {
            navigate("/publicdashboard" + location.search)
        } else {
            if (location.pathname.includes("punchclock")) {
                if (!punchClockAuthToken) {
                    navigate("/punchclock/login")
                } else {
                    try {
                        if (authToken) {
                            localStorage.clear();
                            Settings.set("PUNCHCLOCK_AUTH_TOKEN", punchClockAuthToken)
                        }

                        const punchClock = await api("punchclock/login")  //get punchclock 
                        actions.setPunchClock(punchClock)
                        navigate("/punchclock/timereport")
                    } catch (e) {
                        navigate("/punchclock/login")
                    }
                }
            } else {
                if (!authToken) {
                    if (location.pathname !== '/login') navigate("/login")
                } else {
                    try {
                        const user = await api("/login") // Will verify the token

                        let viewName = location.search || ('?view=' + (user.roleType === 'manager' ? 'resources' : 'projects'))

                        actions.setUser(user)
                        navigate("/timereport/overview" + viewName)
                    } catch (e) {
                        if (location.pathname !== '/login') navigate("/login")
                    }
                }
            }
        }
    }

    updateHeight = () => {
        document.documentElement.style.setProperty("--innerHeight", `${window.innerHeight}px`)
    }

    render() {
        const { reducers, location } = this.props
        const { user, options } = reducers.app
        const classes = createClassName("App", {
            shadows: options.shadows === true
        })

        if (GlobalColors.globalColors.length === 0) return <ActivityIndicator busy />

        const IndexStyling = createGlobalStyle`
            body {
                background: ${props => props.theme.background};
                color: ${props => props.theme.default};
            }
            a {
                color: ${props => props.theme.secondary};
            }
            h2 {
                color: ${props => props.theme.primary};
            }
        `

        return (
            <ThemeProvider theme={GlobalColors.globalColors}>
                <div className={classes}>
                    <IndexStyling />
                    <NavBar location={location} user={user} />

                    <div className="App-content" style={location.pathname === "/publicdashboard" ? {margin: "0px", width: "100%"} : {}}>
                        <Routes>
                            <Route path="/password-reset" element={<ResetPassword />} />
                            <Route path="/publicdashboard" element={<PublicLink />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/timereport/overview" element={<Overview />} />
                            <Route path="/punchclock/login" element={<PunchClockLogin />} />
                            <Route path="/punchclock/timereport" element={<PunchClockBoard />} />
                        </Routes>
                    </div>
                </div>
            </ThemeProvider>
        )
    }
}

// Map Redux state and actions to props
const mapStateToProps = (state) => ({
    router: state.router,
    app: state.app,
});

const mapDispatchToProps = {
    setUser: appActions.setUser,
    setOptions: appActions.setOptions,
    setPunchClock: appActions.setPunchClock,
};

export default connectRedux(mapStateToProps, mapDispatchToProps, App)

// PRIVATE COMPONENTS
class NavBar extends React.PureComponent {
    state = {
        showProfileNav: false
    }

    render() {
        const { location, user } = this.props
        const { showProfileNav } = this.state
        const currentRoute = location.pathname
        const classes = createClassName("App-nav-bar", {
            simple: currentRoute === "/login",
            "show-profile-nav": showProfileNav
        })

        const isManager = user?.roleType === "manager"
        const integrations = user?.integrations || []

        // const logo = window.location.origin + "/logo.png?ver=1"

        if (currentRoute !== "/password-reset") {
            return (
                <Nav 
                    id="nav-bar"
                    className={classes}
                >
                <div>
                    <div className="App-logo">
                        {!["/punchclock/timereport", "/punchclock/login"].includes(currentRoute) ? (
                            <a href="/">
                                <img src={getAssetsURIForPath("/logo.png")} alt="" />
                            </a>
                        ) : (
                            <img src={getAssetsURIForPath("/logo.png")} alt="" />
                        )}
                    </div>
                    {!["/login", "/punchclock/timereport", "/punchclock/login", "/publicdashboard"].includes(currentRoute)
                        && (
                        <MainNav location={location} isManager={isManager} integrations={integrations} />
                    )}
                    {currentRoute !== "/login" && (
                        <Profile
                            data={user}
                            actions={{
                                showNav: this._showProfileNav,
                                hideNav: this._hideProfileNav
                            }}
                        />
                    )}
                </div>
                </Nav>
            )
        } else {
            return <div></div>
        }
    }

    // Internal methods
    _showProfileNav = () => this.setState({ showProfileNav: true })
    _hideProfileNav = () => this.setState({ showProfileNav: false })
}

function MainNav(props) {
    const query = props.location.search ? parseQueryString(props.location.search) : null

    return (
        <nav className="App-mainNav">
            <ul>
                <React.Fragment>
                <MainNavItem
                    title={i18n("timereport", "resources")}
                    icon="perm_contact_calendar"
                    href="/timereport/overview?view=resources"
                    active={(props.isManager && !query) || (query && query.view === "resources")}
                />
                <MainNavItem
                    title={i18n("timereport", "projects")}
                    icon="date_range"
                    href="/timereport/overview?view=projects"
                    active={(!props.isManager && !query) || (query && query.view === "projects")}
                />

                {props.isManager ? (
                    <MainNavItem
                    title={i18n("general", "monitor")}
                    icon="insert_chart"
                    href="/timereport/overview?view=monitor"
                    active={query && query.view === "monitor"}
                    />
                ) : ("")}

                {props.isManager ? (
                    <MainNavItem
                    title={i18n("general", "globals")}
                    icon="public"
                    href="/timereport/overview?view=globals"
                    active={query && query.view === "globals"}
                    />
                ) : ("")}

                {props.isManager && props.integrations.includes("fortnox") ? (
                    <MainNavItem
                    title={"Fortnox"}
                    icon="savings"
                    href="/timereport/overview?view=fortnox"
                    active={query && query.view === "fortnox"}
                    />
                ) : ("")}
                </React.Fragment>
            </ul>
        </nav>
    )
}

function MainNavItem(props) {
    const classes = createClassName("", {
        active: !!props.active,
        disabled: !!props.disabled
    })

    return (
        <li className={classes} title={props.title}>
            <Link to={props.href}>
                <Span className="icon material-icons">{props.icon}</Span>
            </Link>
        </li>
    )
}

function Profile(props) {
    const { data, actions } = props
    const apiVersion = localStorage.getItem("TIMR_API_VERSION")
    const ListItem = ({ label, icon, appearance, onClick }) => (
        <Li className="item" onClick={onClick}>
            <I className={createClassName("icon logout material-icons", {
                [appearance]: !!appearance
            })}>
                {icon}
            </I>
            <Span className="label">{label}</Span>
        </Li>
    )

    if (!data) return null

    return (
        <div className="App-profile">
            <div>
                <button
                    className="profile-button"
                    onClick={actions.showNav}
                    style={{
                        backgroundImage: `url(${getAssetsURIForPath(data.avatar)})`
                    }}
                    title={`${data.firstName} ${data.lastName}`}
                />
            </div>
            <div className="nav-container">
                <div className="overlay" onClick={actions.hideNav} />
                <Nav className="nav">
                    <ul>
                        {/* <ListItem label={ i18n("general", "options") } icon="settings" /> */}
                        <Li className="item simple">
                            <Span className="label">TIMR: v{packgeJson.version}</Span>
                        </Li>
                        <Li className="item simple">
                            <Span className="label">API: v{apiVersion}</Span>
                        </Li>
                        <ListItem
                            label={i18n("general", "sign_out")}
                            icon="exit_to_app"
                            appearance="danger"
                            onClick={_signOut}
                        />
                    </ul>
                </Nav>
            </div>
        </div>
    )
}

// PRIVATE FUNCTIONS
function _signOut() {
    localStorage.clear()
    // Settings.set("LANGUAGE", "en");
    window.location.href = "/"
}

function _setupOptions() {
    this.props.actions.setOptions({ shadows: false })
}

const Li = styled.li`
    ${props => props.className === "item" && css`
        &:hover {
            background: ${props.theme.background} !important;
            cursor: pointer;
        }
        &:hover .label {
            color: ${props.theme.subtitle} !important;
        }
    `};
`

const Nav = styled.nav`
    background: ${(props) => props.theme.sidebar} !important;
`

const Span = styled.span`
    ${props => props.className.split(" ").includes("icon") && css`
        color: ${props.theme.sidebar_icon};
    `};
    ${props => props.className === "label" && css`
        color: ${props.theme.label} !important;
    `};
`

const I = styled.i`
    ${props => props.className.split(" ").includes("icon") && css`
        color: ${props.theme.label};
    `};
    ${props => props.className.split(" ").includes("logout") && css`
        color: ${props.theme.danger} !important;
    `};

    &:hover {
        background: ${(props) => props.theme.background} !important;
    }
`