import React from "react";

// CLASSES
import Settings from "classes/Settings";
import styled, {css} from 'styled-components';

// COMPONENTS
import ActivityIndicator from "components/ActivityIndicator/ActivityIndicator";
import DatePicker from "components/DatePicker/DatePicker";
import Modal from "components/Modal/Modal";
import Statistics from "components/Statistics/Statistics";

import Timeline from "components/Timeline/Timeline";

import ContextReviewContent from "../../../components/ContextReviewContent/ContextReviewContent";
import FilterView from "../../../components/FilterView/FilterView";

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// HELPERS
import * as utils from "helpers/utils";
import api from "helpers/api";
import i18n from "helpers/i18n";

export default class ResourcesView extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            period: null,
            timelines: null,
            unreportedDates: null,
            totalHours: 0,
            totalFee: 0,
            hasOpenedReportTimeWindowOnce: true,
            filterViewVisible: false,
            contextReviewContent: null,
            newResourceContent: null,
        }
    }

    async componentDidMount() {
        this._isMounted = true
        const { timeReporter } = this.props

        timeReporter.addListener("change", this._onTimeReporterChange)

        if (!timeReporter.getTimelines("resources")) await this.props.fetchDataAsync()
        // else _refreshTimeReportData.call(this)
    }

    componentWillUnmount() {
        this._isMounted = false
        const { timeReporter } = this.props
        timeReporter.removeListener("change", this._onTimeReporterChange)
        timeReporter.clearTimelines("resources")
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        const orderedTimelines = reorder(
          this.state.timelines,
          result.source.index,
          result.destination.index,
        );

        const sourceUserId = result.draggableId;
        const destinationUserId = this.state.timelines.filter((timeline,index) => {
            return index === result.destination.index;
        })[0].id;

        const data = {
          id: this.props.user.id,
          sourceUserId: sourceUserId,
          destinationUserId: destinationUserId
        };

        const url = '/users/saveResourceOrder';
        api(url, data);

        this.setState({
          timelines :orderedTimelines ,
        });
    }

    render() {
        const { props, state } = this;
        const {
            busy,
            // parentProps,
            onHover } = props;

        if(!state.timelines) return <ActivityIndicator busy />;

        const mode = Settings.get("OVERVIEW_MODE_resources_mode");

        const classes = utils.createClassName("TimeReportOverview-timelines", {
        //   "noscroll": !!state.contextReviewContent || !!state.newResourceContent
        });

        return (
            <div className="TimeReportOverview-Resources">
                <div className="container">
                    <TopBar
                        mode={ mode }
                        parentProps={ props }
                        parentState={ state }
                        onFilterToggle={ this._onFilterToggle }
                        onModeToggle={ this._onModeToggle }
                        onNewResource={ this._onNewResource }
                    />

                    { busy ? (
                        <ActivityIndicator busy />
                    ) : (
                        <React.Fragment>

                        <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                            <Droppable droppableId="droppable">
                                {(droppableProvided, droppableSnapshot) => (
                                    <div id="draggable-container" ref={droppableProvided.innerRef}>
                                        <div className={classes}>
                                            {state.timelines.map((timeline, index) => (
                                                <Draggable key={timeline.id} draggableId={timeline.id.toString()} index={index}>
                                                {(draggableProvided, draggableSnapshot) => (
                                                    <div className='draggable-timeline'
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={getItemStyle(
                                                            draggableSnapshot.isDragging,
                                                            draggableProvided.draggableProps.style,
                                                            index === 0
                                                        )}
                                                    >
                                                    <Timeline
                                                        key={ `Timeline-${index}` }
                                                        index={ index }
                                                        period={ state.period }
                                                        data={ timeline }
                                                        mode={ mode }
                                                        view='resources'
                                                        onHover = { onHover }
                                                        onCellClick={ this._onCellClick }
                                                        onContextReviewClick={ this._onContextReviewClick }
                                                        />
                                                    </div>
                                                )}
                                                </Draggable>
                                            ))}
                                            {droppableProvided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                          </DragDropContext>

                            <Modal visible={!!state.contextReviewContent} onClosed={this._onContextReviewModalClosed.bind(this, false)}>
                                {state.contextReviewContent}
                            </Modal>

                            <Modal visible={!!state.newResourceContent} onClosed={this._onContextReviewModalClosed.bind(this, false)}>
                                {state.newResourceContent}
                            </Modal>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }

    // Internal methods

    _onContextReviewModalClosed = (fetch = true) => {
        document.getElementsByClassName("TimeReportOverview-Resources")[0].style.overflowY = "auto"
        this.setState({
            busy: fetch,
            contextReviewContent: null,
            newResourceContent: null
        }, async () => {
            if (fetch) {
                // Fetch and load time report data
                await this.props.fetchDataAsync()
                this.setState({ busy: false })
            }
        })
    }

    _onTimeReporterChange = async () => {
        await _refreshTimeReportData.call(this);
    }

    _onFilterToggle = () => {
        this.setState(prevState => ({ filterViewVisible: !prevState.filterViewVisible }));
    }

    _onModeToggle = () => {
        this.setState(prevState => ({ filterModeVisible: !prevState.filterModeVisible }));
    }

    _onNewResource = () => {
        document.getElementsByClassName("TimeReportOverview-Resources")[0].style.overflowY = "hidden"
        this.setState({
            newResourceContent: <ContextReviewContent writeable={ true } type="resource" isNew onSave={ this._onContextReviewModalClosed } />
        });
    }

    _onCellClick = (context) => {
        this.props.onHover && this.props.onHover(null)
        if (context.dataset && context.dataset.length === 0) return

        let dataset = context.dataset.filter(e => !e.placeholder)
        // prevents reversing the already reversed dataset
        if (!context.sorted) dataset.reverse()
        context.sorted = true

        context.isCellClick = true
        context.dataset = dataset
        context.resourceid = context.dataset[0].resourceId
        context.projectid = context.dataset[0].projectId
        context.spread = context.dataset[0].spread
        context.shadow = context.dataset.every(e => e.shadow)
        this.props.setTimeReportData(context)
    }

    _onContextReviewClick = (id, context) => {
        const { props } = this;
        const { user } = props;
        const isManager = user.roleType === "manager";
        document.getElementsByClassName("TimeReportOverview-Resources")[0].style.overflowY = "hidden"
        this.setState({
            contextReviewContent: (
                <ContextReviewContent
                    writeable={ isManager }
                    id={ id }
                    context={ context }
                    type="resource"
                    onSave={ this._onContextReviewModalClosed }
                />
            )
        });
    }
}

// PRIVATE FUNCTIONS

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle, isFirstChild) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `10px 0`,
  ...draggableStyle,
});

// PRIVATE COMPONENTS
class TopBar extends React.PureComponent {

    render() {
        const { props } = this;
        const { parentProps, parentState, mode, onFilterToggle, onModeToggle, onNewResource } = props;
        const { user,onJump, onDateChange, onFilterChange } = parentProps;
        const { period, totalHours, totalFee, totalCost, totalSalary, overheadCost, overheadFee, filterViewVisible, filterModeVisible } = parentState;
        const isManager = user.roleType === "manager";

        return (
            <Div className="TimeReportOverview-top-bar">
                <div className="start">
                    <div className="period">
                        <DatePicker
                            type="range"
                            startDate={ utils.moment(period.startDate) }
                            endDate={ utils.moment(period.endDate) }
                            onDateChange={ onDateChange }
                            onJump={ onJump }
                        />
                    </div>

                    <Statistics
                        type="total"
                        data={{
                            overheadFee,
                            overheadCost,
                            totalHours,
                            totalFee,
                            totalCost,
                            totalSalary
                        }}

                        isManager={ isManager }
                        mode={ mode }
                        modeType={'resources_mode'}
                        filterModeVisible={ filterModeVisible }
                        
                        onModeToggle={ onModeToggle }
                        onFilterChange={ onFilterChange }
                    />
                </div>

                <div className="end">
                    <div className={ utils.createClassName("filter-container", { "opened": filterViewVisible }) }>
                        <StyledButton className={ utils.createClassName("button", { "opened": filterViewVisible }) } title={ i18n("general", "filter") } onClick={ onFilterToggle }>
                            <div className="icon material-icons">remove_red_eye</div>
                        </StyledButton>
                        <FilterView isManager={isManager} type="resources" visible={ filterViewVisible } onClose={ onFilterToggle } onFilterChange={ onFilterChange } />
                    </div>
                    { isManager && (
                        <StyledButton className="button" title={ i18n("timereport", "new_resource") } onClick={ onNewResource }>
                            <div className="icon material-icons">add</div>
                        </StyledButton>
                    )}
                </div>
            </Div>
        )
    }
}


// PRIVATE FUNCTIONS
async function _refreshTimeReportData() {
    const { timeReporter } = this.props
    const period = timeReporter.getCurrentPeriod()
    const unreportedDates = timeReporter.getUnreportedDates("projects")
    const timelines = timeReporter.getTimelines("resources")
    let overheads = timeReporter.getOverheads()

    if (this._isMounted && timelines) {
        const { totalHours, totalFee, totalCost, totalSalary } = utils.getTimelineStatistics(timelines, period, "resources")
        const { overheadFee, overheadCost } = utils.getTotalOverhead(overheads, period, timeReporter)

        this.setState({
            period,
            timelines,
            unreportedDates,
            totalHours,
            totalFee,
            totalCost,
            totalSalary,
            overheadFee,
            overheadCost
        }, () => {
            // Open ReportTimeModal if there are unreported dates (and if first time)
            if (unreportedDates && unreportedDates.length > 0 && !this.state.hasOpenedReportTimeWindowOnce) {
                this.setState({ hasOpenedReportTimeWindowOnce: true }, () => this.props.setTimeReportData(unreportedDates[0]))
            }
        })
    }
}

const Div = styled.div`
    ${props => props.className === 'TimeReportOverview-top-bar' && css`
        background: ${props.theme.overlay};
    `}
    ${props => props.className === 'statistics' && css`
        border-left: 1px solid ${props.theme.background};
        @media (min-width: 640px) {
            &:hover {
                background: ${props.theme.background};
            }
        }
        // @media (max-width: 640px) {
        //     border-top: 1px solid ${props.theme.background} !important
        // }
    `}
    ${props => props.className === 'label' && css`
        color: ${props.theme.label};
    `}
    ${props => props.className.split(' ').includes('icon') && css`
        color: ${props.theme.label};
    `}
`;

const StyledButton = styled.div`
    color: ${props => props.theme.subtitle};
    &:hover {
      background: ${props => props.theme.background};
    }

    ${props => props.className.split(' ').includes('opened') && css`
        background: ${props.theme.overlay} !important;
    `}
`;
