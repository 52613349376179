import React, { Component } from "react"
//COMPONENTS
import Stopwatch from "components/Stopwatch/Stopwatch"
import Button from "components/Button/Button"
import MessageBox from "components/MessageBox/MessageBox"
//HELP
import i18n from "helpers/i18n"
import Settings from "classes/Settings"
import styled from "styled-components"

export default class PunchClock extends Component {
    constructor(props) {
    super(props)
        this.state = {
            running: !!props.resource.activeProjects.includes(props.project.Name),
            seconds: 0,
            minutes: 0,
            hours: 0,
            activeProjects: props.resource.activeProjects
        }
    }

    componentDidMount() {
        const { props } = this
        if (props.resource.startArray[props.project.Name]) {
            this.setTimeElapsed(props.resource.startArray[props.project.Name], props.resource.stopArray[props.project.Name])
        }
        this.setState({ componentDidMount: true })
    }

    componentDidUpdate() {
        const { state, props } = this
        if (props.resource.activeProjects.length !== state.activeProjects.length) {
            this.setState({
                running: !!props.resource.activeProjects.includes(props.project.Name),
                activeProjects: props.resource.activeProjects
            })
            this.setTimeElapsed(props.resource.startArray[props.project.Name], props.resource.stopArray[props.project.Name])
        }
    }

    onClose = () => {
        this.props.onClose()
    }

    reportTime = (
        userId,
        projectId,
        startArray,
        stopArray,
        hours,
        salaryMultiplier
    ) => {
        this.props.reportTime(
            userId,
            projectId,
            startArray,
            stopArray,
            hours,
            salaryMultiplier
        )
    }

    setTimeElapsed = (startArray, stopArray) => {
        let HSM = this.props.timeElapsed(startArray, stopArray)
        this.setState({
            hours: HSM.hours,
            minutes: HSM.minutes,
            seconds: HSM.seconds,
        })
        return HSM
    }

    onStartMultipleProjects() {
        //double check for active project
        if (!this.props.resource.activeProjects[0]) {
            this.onStartHandler()
        } else {
            MessageBox(this, {
                title: "Warning",
                message:
                    "Project " +
                    this.props.resource.activeProjects[0] +
                    " is already active, do you want to start " +
                    this.props.project.Name +
                    " anyway?",
                buttons: [
                    {
                        label: "Confirm",
                        appearance: "primary",
                        onClick: () => {
                            this.onStartHandler()
                        }
                    },
                    {
                        label: "Cancel",
                        appearance: "danger"
                    }
                ]
            })
        }
    }

  
    onStartHandler() {
        this.setState({running: true})
        this.props.resourceTarget.style.background = Settings.getGlobalColor('success')
        const timeElapsed = this.props.onStartHandler(this.props.resource, this.props.project)

        this.setState({
            hours: timeElapsed.hours,
            minutes: timeElapsed.minutes,
            seconds: timeElapsed.seconds,
        })
    }

    onStopHandler() {
        this.setState({running: false})
        this.props.resourceTarget.style.background = Settings.getGlobalColor('overlay')
        const timeElapsed = this.props.onStopHandler(this.props.resource, this.props.project)

        this.setState({
            hours: timeElapsed.hours,
            minutes: timeElapsed.minutes,
            seconds: timeElapsed.seconds,
        })
    }
  
    onSingleReset = (resource, project) => {
        this.props.onSingleReset(resource, project)
    }

    checkActiveProjects() {
        //if another project is running, show warning
        if (this.state.activeProjects.length > 0) {
            this.onStartMultipleProjects()
        } else {
            this.onStartHandler()
        }
    }

    render() {
        const { running, hours, minutes, seconds, componentDidMount } = this.state
        const formattedHours = hours < 10 ? "0" + hours : hours
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes
        const formattedSeconds = seconds < 10 ? "0" + seconds : seconds

        return (
            <div>
                {this.state.MessageBox}
                <div
                    className="stopwatch-card-container"
                    onClick={
                        !running
                        ? this.checkActiveProjects.bind(this)
                        : this.onStopHandler.bind(this)
                    }
                >
                    {!running ? (
                        <React.Fragment>
                            <center>
                                <Button
                                    type="submit"
                                    size="small"
                                    label={i18n("general", "start")}
                                    appearance={"primary"}
                                    filled
                                />
                            </center>
                          <center>
                                <Div $active={running} className="stopwatch-container">
                                    <span className="stopwatch">
                                        {formattedHours + ":" + formattedMinutes + ":" + formattedSeconds}
                                    </span>
                                </Div>
                          </center>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <center>
                                <Button
                                    type="submit"
                                    size="small"
                                    label={i18n("general", "stop")}
                                    appearance="danger"
                                    filled
                                />
                            </center>
                            <center>
                                <Div $active={running} className="stopwatch-container">
                                    {componentDidMount && (
                                        <Stopwatch
                                            hours={hours}
                                            minutes={minutes}
                                            seconds={seconds}
                                        />
                                    )}
                                </Div>
                            </center>
                        </React.Fragment>
                    )}
                </div>
            </div>
        )
    }
}

const Div = styled.div`
    color: ${props => props.$active ? props.theme.background : props.theme.primary}
`